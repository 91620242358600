import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAddToHomeMutation } from '../../redux/Features/ServicesApi';
import swal from 'sweetalert';
import ProductsSkeleton from '../Skeleton/Products/ProductsSkeleton';

const ProductsComponent = ({ products, grid, isLoading }) => {
  const navigate = useNavigate();
  const [ip, setIp] = useState(null);

  const [addToCart] = useAddToHomeMutation();

  useEffect(() => {
    //  Fetch IP address and set it to the state
    const fetchIpAddress = async () => {
      try {
        const response = await axios.get('https://api.ipify.org?format=json');
        setIp(response.data.ip);
      } catch (error) {
        // console.error("Error fetching IP address:", error);
        setIp('127.0.0.1');
      }
    };
    fetchIpAddress();
  }, []);

  const handler = async (id) => {
    try {
      const res = await addToCart({
        ip_address: { ip_address: ip },
        id: id,
      }).unwrap();
      if (res?.success) {
        swal('Good job!', 'Add to cart successful!', 'success');
        navigate('/checkout');
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (isLoading) {
    return <ProductsSkeleton />;
  }

  if (!products?.length > 0) {
    return (
      <div className="mx-auto text-center text-xl">
        <p>No products found for this category.</p>
      </div>
    );
  }

  return (
    <>
      <div className={`${grid} items-center gap-5`}>
        {products.length > 0 &&
          products?.map((p, index) => (
            <div key={index}>
              <Card
                sx={{ maxWidth: 1000 }}
                className="my-2 border border-primary-500"
              >
                <Link to={`/product-details/${p?.id}/${p?.slug}`}>
                  <CardMedia component="img" image={p?.imageUrl} alt="Men" />
                  <CardContent>
                    <div>
                      <p className="text-lg text-secondary-500 h-14">
                        {p?.name?.length > 25
                          ? `${p?.name.slice(0, 25)}...`
                          : p?.name}
                      </p>
                      <p>৳ {Number(p?.regular_price).toFixed()} TK.</p>
                    </div>
                  </CardContent>
                </Link>

                <CardActionArea>
                  <Button
                    variant="contained"
                    className="w-full"
                    onClick={() => handler(p?.id)}
                  >
                    Order Now
                  </Button>
                </CardActionArea>
              </Card>
            </div>
          ))}
      </div>
    </>
  );
};

export default ProductsComponent;
