import React, { useEffect } from 'react';
import DOMPurify from 'dompurify';
import Title from '../../component/Helmet/Title';
import { usePrivacyPolicyQuery } from '../../redux/Features/ServicesApi';

const TermsCondition = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { data } = usePrivacyPolicyQuery();
  const privacy = DOMPurify.sanitize(data?.data?.privacy_policy);

  return (
    <section className="mt-24 lg:mt-0">
      <Title
        title={'Terms & Condition'}
        content={'This is terms & condition page of chader alo.'}
      />
      <h3 className="text-center my-5 lg:my-10">Terms & Condition</h3>
      <div className="container mx-auto px-4">
        <p className="text-md font-medium">
          {' '}
          Thank you for providing this information about Salvia.clothing's terms
          and conditions. It's a good practice to remind customers to review the
          terms regularly, as they may change over time. If you need help
          drafting a full set of terms and conditions or want to include
          specific sections (like payment terms, shipping policies, returns,
          etc.), let me know—I’d be happy to assist!
        </p>
        {/* Order Policy */}
        <section className="my-8">
          <h2 className="text-2xl font-semibold mb-4">Order Policy:</h2>
          <ul className="list-disc list-inside">
            <li>
              <strong>Availability:</strong> Orders can only be placed for items
              that are currently available in stock.
            </li>
            <li>
              <strong>Payment and Cancellation:</strong> Once an order is paid
              for, it cannot be canceled or refunded, regardless of the payment
              method (Cash on Delivery (COD) or Online Payment).
            </li>
            <li>
              <strong>Order Confirmation:</strong> Home Delivery orders will be
              processed only after confirmation from our Customer Care
              representative.
            </li>
            <li>
              <strong>Order-Related Issues:</strong> For any issues related to
              your order, please contact our Customer Care team.
            </li>
            <div className="my-4">
              <p className="text-2xl font-semibold">
                Customer Care: <br />
              </p>{' '}
              <li>
                <b>Contact:</b> phone: 01898801900 <br />
              </li>
              <li>
                {' '}
                <strong>Service Hours:</strong> 9:00 AM to 7:00 PM, available
                throughout the week.
              </li>
            </div>
          </ul>
        </section>
        {/* Pricing Policy */}
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Pricing Policy:</h2>
          <ul className="list-disc list-inside">
            <li>
              <strong>Exclusion of VAT & TAX:</strong> All product prices listed
              on our website are exclusive of VAT and TAX.
            </li>
            <li>
              <strong>Price Variations:</strong> Prices of products may differ
              between Salvia's brick-and-mortar stores and the website due to
              periodic promotional offers.
            </li>
            <li>
              <strong>Pricing Errors:</strong> Occasionally, technical errors in
              pricing information may occur due to system malfunctions. In such
              cases, Salvia.clothing reserves the right to cancel the order.
            </li>
          </ul>
        </section>
        {/* Shipping Policy */}
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Shipping Policy:</h2>
          <ul className="list-disc list-inside">
            <li>
              <strong>Inside Dhaka:</strong> Delivery within 3 business days.
            </li>
            <li>
              <strong>Outside Dhaka:</strong> Delivery within 5 business days.
            </li>
            <li>
              <strong>Third-Party Shipping:</strong> We collaborate with a
              third-party shipping company to deliver orders. The delivery
              process is not entirely within our control.
            </li>
            <li>
              <strong>Compensation for Damage:</strong> We strive to ensure your
              order arrives safely. However, if any damage occurs during
              transit, compensation will be provided.
            </li>
          </ul>
        </section>
        {/* Color Inconsistency */}
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Color Inconsistency:</h2>
          <p>
            We strive to display the colors of our products as accurately as
            possible. However, due to differences in monitor settings, the
            actual color may vary slightly.
          </p>
        </section>
        {/* Information Inconsistency Disclaimer */}
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">
            Information Inconsistency Disclaimer:
          </h2>
          <p>
            Due to technical malfunctions, the website may occasionally display
            inaccurate information related to product prices, availability,
            pictures, sizes, or colors. Salvia.clothing reserves the right to
            correct and update such information as needed.
          </p>
        </section>
        {/* Bengali Section */}
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">নিয়ম এবং শর্তাবলী:</h2>
          <p>
            salvia.clothing-এ আপনাকে স্বাগতম এবং আমাদের প্রতি আপনার আগ্রহের জন্য
            ধন্যবাদ। আমাদের নিয়ম ও শর্তাবলী মূল্যায়ন করার আগে অনুগ্রহ করে মনে
            রাখবেন, এগুলি যেকোনো সময় পরিবর্তিত হতে পারে। তাই, প্রতিবার অর্ডার
            দেওয়ার আগে দয়া করে আমাদের নিয়ম ও শর্তাবলী দেখে নিন।
          </p>

          <h3 className="text-xl font-semibold my-4">অর্ডার নীতিমালা:</h3>
          <ul className="list-disc list-inside">
            <li>
              <b>স্টক থাকা পণ্য:</b> শুধুমাত্র স্টকে থাকা পণ্যের জন্য অর্ডার করা
              সম্ভব।
            </li>
            <li>
              <b>অগ্রিম অর্থ প্রদান ও বাতিলকরণ:</b> ক্যাশ অন ডেলিভারি (COD) এবং
              অনলাইন পেমেন্ট উভয়ের ক্ষেত্রেই, একবার অগ্রিম অর্থ প্রদান করা হলে
              অর্ডার বাতিল করা বা ফেরত নেওয়া সম্ভব নয়।
            </li>
            <li>
              <b> অর্ডার নিশ্চিতকরণ:</b> হোম ডেলিভারি অর্ডারগুলি আমাদের কাস্টমার
              কেয়ার প্রতিনিধির মাধ্যমে নিশ্চিতকরণের পরে প্রক্রিয়া করা হবে।
            </li>
            <li>
              <b>অর্ডার সম্পর্কিত সমস্যা:</b> অর্ডার সংক্রান্ত যেকোনো সমস্যার
              জন্য আমাদের কাস্টমার কেয়ারের সাথে যোগাযোগ করুন।
            </li>
          </ul>
          <h3 className="text-xl font-semibold my-4">
            কাস্টমার কেয়ারের যোগাযোগ নম্বর:
          </h3>
          <ul className="list-disc list-inside">
            <li>
              <b>ফোন:</b> 01898801900
            </li>
            <li>
              <b>সেবা সময়:</b> সকাল ১০ টা থেকে সন্ধ্যা ৯ টা পর্যন্ত, সপ্তাহের
              যেকোনো দিন।
            </li>
          </ul>
          <h3 className="text-xl font-semibold my-4">মূল্য নীতি:</h3>
          <ul className="list-disc list-inside">
            <li>
              <b>মূল্য নির্ধারণ:</b> আমাদের ওয়েবসাইটে প্রদর্শিত সমস্ত পণ্যের
              মূল্য ভ্যাট এবং ট্যাক্স ছাড়া উল্লেখ করা হয়েছে।
            </li>
            <li>
              <b>দাম ভিন্নতা:</b> প্রচারণামূলক অফারের কারণে সালভিয়ার আউটলেট এবং
              ওয়েবসাইটে একটি পণ্যের দাম ভিন্ন হতে পারে।
            </li>
            <li>
              <b> মূল্য প্রদর্শনে ত্রুটি:</b> সিস্টেমের ত্রুটির কারণে পণ্যের
              মূল্য প্রদর্শনে ভুল থাকতে পারে। এ ধরনের পরিস্থিতিতে, সালভিয়া যে
              কোনো অর্ডার বাতিল করার অধিকার রাখে। এ ধরনের যে কোনো অসুবিধার জন্য
              আমরা আন্তরিকভাবে দুঃখিত।
            </li>
          </ul>
          <h3 className="text-xl font-semibold my-4">ডেলিভারি নীতি:</h3>
          <p>
            আমরা আপনার অর্ডার সঠিকভাবে, ভাল অবস্থায় এবং সময়মতো পৌঁছে দিতে
            প্রতিশ্রুতিবদ্ধ। অনুগ্রহ করে আমাদের ডেলিভারি নীতিগুলি নিম্নরূপ মনে
            রাখুন:
          </p>
          <ul className="list-decimal list-inside">
            <li>ঢাকার ভিতরে ডেলিভারি: ৩ কার্যদিবসের মধ্যে ডেলিভারি।</li>
            <li>ঢাকার বাইরে ডেলিভারি: ৫ কার্যদিবসের মধ্যে ডেলিভারি।</li>
            <p className="my-4">
              আমরা অর্ডার প্রদানের জন্য একটি লজিস্টিক কোম্পানির সাথে যুক্ত। তাই
              ডেলিভারি প্রক্রিয়া পুরোপুরি আমাদের নিয়ন্ত্রণে নয়। ডেলিভারির
              সময় কোনো ধরনের ক্ষতি হলে, আমরা তার জন্য আন্তরিকভাবে দুঃখিত এবং
              ক্ষতিপূরণ দেওয়ার প্রতিশ্রুতি দিচ্ছি।
            </p>
            <hr />
            <div className="my-4">
              <p>
                <b>রঙের অসঙ্গতি:</b>
              </p>
              <p>
                আমরা আমাদের ওয়েবসাইটে পণ্যের সঠিক রঙ প্রদর্শনের জন্য সর্বাত্মক
                চেষ্টা করেছি। তবে, স্ক্রিনের রেজোলিউশন এবং সেটিংসের কারণে রঙের
                কিছু ভিন্নতা দেখা দিতে পারে।
              </p>
            </div>
            <hr />
            <div className="my-4">
              <p>
                <b>তথ্য অসংগতি দাবিত্যাগ:</b>
              </p>
              <p>
                প্রযুক্তিগত ত্রুটির কারণে ওয়েবসাইটে পণ্যের মূল্য, প্রাপ্যতা,
                ছবি, আকার এবং রঙ সম্পর্কিত অযথার্থ তথ্য প্রদর্শিত হতে পারে।
                সালভিয়া এই ধরনের তথ্য সংশোধন এবং আপডেট করার ক্ষমতা সংরক্ষণ করে।
              </p>
            </div>
            <hr />
            <div className="my-4">
              <p>
                এইভাবে নীতিগুলি আরও সুসংগঠিত ও পরিপূর্ণভাবে উপস্থাপন করা হয়েছে।
                যদি আরও কিছু পরিবর্তন বা সংযোজন করতে চান, জানাবেন!
              </p>
            </div>
          </ul>
        </section>
        {/* Other Bengali Sections */}
        {/* Add the rest of the Bengali sections similarly */}
      </div>
    </section>
  );
};

export default TermsCondition;
