import React from 'react';

const ShippingCharges = () => {
  return (
    <div className="bg-gray-100 mt-24 lg:mt-0 py-12 px-4 sm:px-6 lg:px-8">
      <div className=" mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="px-6 py-8">
          <h1 className="text-3xl font-bold text-center text-gray-800 mb-8">
            Shipping & Charges
          </h1>

          <p className="text-gray-600 mb-6">
            After selecting your desired product from the checkout page, you
            have to choose shipping options, to confirm how the item will be
            delivered to you. We are offering below shipping method for your
            conveniences –
          </p>

          <h2 className="text-2xl font-semibold text-gray-800 mb-4">
            Standard shipping
          </h2>
          <ul className="list-disc pl-6 mb-6 text-gray-600">
            <li>Inside Dhaka: Delivers within 1-3 business days</li>
            <li>Outside Dhaka: Delivers within 3-5 business days</li>
          </ul>

          <div className="mb-6"></div>

          <h2 className="text-2xl font-semibold text-gray-800 mb-4">
            Shipping Policy:
          </h2>
          <p className="text-gray-600 mb-4">
            We are committed to delivering your order accurately, in good
            condition, and on time. Please note our shipping policy as follows:
          </p>
          <ol className="list-decimal pl-6 mb-6 text-gray-600">
            <li>Inside Dhaka: Delivery within 3 business days, cost: 70 Tk.</li>
            <li>
              Outside Dhaka: Delivery within 5 business days, cost: 150 Tk.
            </li>
          </ol>

          <p className="text-gray-600 mb-6">
            Please be aware that we are partnered with a logistics company for
            order fulfillment. Therefore, the delivery process is not entirely
            within our control. We apologize for any damage that may occur
            during delivery and promise to provide compensation if needed.
          </p>

          <p className="text-gray-600 italic">
            *For every individual item VAT will be applicable (7.5% for
            clothing, 5% for accessories)
          </p>
        </div>
      </div>
    </div>
  );
};

export default ShippingCharges;
