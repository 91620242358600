import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import './Banner.css';
import { useSlidersQuery } from '../../../redux/Features/ServicesApi';
import { Link } from 'react-router-dom';
import BannerSkeleton from '../../Skeleton/BannerSkeleton';

const Banner = () => {
  const { data: sliders, isLoading } = useSlidersQuery();

  return (
    <div>
      {isLoading ? (
        <BannerSkeleton />
      ) : (
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
        >
          {sliders?.data?.map((b, index) => (
            <SwiperSlide key={index}>
              <Link to={'/'}>
                <img
                  src={b?.imageUrl}
                  alt="banner"
                  className="rounded-md w-full"
                />
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  );
};

export default Banner;
