import React, { useEffect } from 'react';
import DOMPurify from 'dompurify';
import Title from '../../component/Helmet/Title';
import { useAboutUsQuery } from '../../redux/Features/ServicesApi';

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { data } = useAboutUsQuery();
  const about = DOMPurify.sanitize(data?.data?.about);
  return (
    // <!-- contact us section -->
    <section className="mt-24 lg:mt-0">
      <Title
        title={'About us'}
        content={'This is about us Page of chader alo.'}
      />

      <div className="my-10 text-center mx-auto">
        <div dangerouslySetInnerHTML={{ __html: about }} className="mx-auto" />
      </div>
    </section>
  );
};

export default About;
