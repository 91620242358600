import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  useCountCartProductsQuery,
  useGetCartProductsQuery,
} from '../../redux/Features/ServicesApi';
import axios from 'axios';

// Cart dropdown
const CartDropdown = ({ isOpen }) => {
  const [ip, setIp] = useState('');

  useEffect(() => {
    //  Fetch IP address and set it to the state
    const fetchIpAddress = async () => {
      try {
        const response = await axios.get('https://api.ipify.org?format=json');
        setIp(response.data.ip);
      } catch (error) {
        // console.error("Error fetching IP address:", error);
        setIp('127.0.0.1');
      }
    };
    fetchIpAddress();
  }, []);

  // cart products.
  const { data } = useGetCartProductsQuery(ip);

  // cart products count.
  const { data: count } = useCountCartProductsQuery(ip);

  return (
    <div>
      {/* check is dropdown open show the dropdown contents */}
      {isOpen && (
        <div
          className="py-1"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          <div className="px-4 py-3">
            <h3 className="text-lg font-semibold">
              Shopping Bag ({count?.data})
            </h3>
            <div className="h-60 overflow-y-scroll">
              {data?.data?.carts?.map((c, index) => (
                <div className="mt-3 flex" key={index}>
                  <img
                    src={c?.product?.imageUrl}
                    alt="Men's Knit T-Shirt"
                    className="w-20 object-cover rounded"
                  />
                  <div className="ml-3">
                    <p className="text-sm font-medium text-gray-900">
                      {c?.product?.name}
                    </p>
                    <p className="text-sm text-red-600 font-semibold">
                      ৳ {Number(c?.product?.regular_price).toFixed() * c?.qty}
                    </p>
                    <p className="text-xs text-gray-500">Qty: {c?.qty}</p>
                    <p className="text-xs text-gray-500">
                      Variant: {c?.size ? c?.size : 'No size'}-
                      {c?.color ? c?.color : 'No color'}
                    </p>
                  </div>
                </div>
              ))}
            </div>
            <div className="mt-3">
              <p className="text-sm font-medium text-gray-900">
                Subtotal:{' '}
                <span className="font-semibold">৳ {data?.data?.subTotal}</span>
              </p>
            </div>
            <div className="mt-4 flex space-x-2 mx-auto justify-center items-center">
              <Link to={'/cart'}>
                <button className="w-full bg-gray-800 text-white py-2 px-4 rounded hover:bg-gray-700 transition duration-200">
                  View cart
                </button>
              </Link>
              <Link to={'/checkout'}>
                <button className="w-full bg-red-600 text-white py-2 px-4 rounded hover:bg-red-700 transition duration-200">
                  Checkout
                </button>
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CartDropdown;
