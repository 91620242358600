import { createBrowserRouter } from 'react-router-dom';
import Main from '../layout/Main';
import Error from '../pages/Error';
import Home from '../pages/Home';
import Playground from '../pages/Playground';
import Products from '../pages/Products/Products';
import ProductDetails from '../pages/Products/ProductDetails';
import ViewCart from '../pages/ViewCart';
import Checkout from '../pages/Checkout';
import Compare from '../pages/Compare';
import Categories from '../pages/Categories';
import About from '../pages/Privacy/About';
import Contact from '../pages/Privacy/Contact';
import Privacy from '../pages/Privacy/Privacy';
import Payment from '../pages/Privacy/Payment';
import TermsCondition from '../pages/Privacy/TermsCondition';
import SuccessOrder from '../pages/SuccessOrder';
import SearchProducts from '../pages/Products/SearchProducts';
import Return from '../pages/Privacy/Return';
import ShippingCharges from '../pages/Privacy/ShippingCharges';
import CustomerService from '../pages/Privacy/CustomerService';
import StoreLocator from '../pages/Privacy/StoreLocator';
import SizeGuide from '../pages/Privacy/SizeGuide';
import DigitalGiftVoucherPage from '../pages/Privacy/DigitalGiftVoucherPage';
import ChildCategoryProducts from '../pages/Products/ChildCategoriesProducts';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Main />,
    errorElement: <Error />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: '/categories',
        element: <Categories />,
      },
      {
        path: '/products-category/:slug',
        element: <Products />,
      },
      {
        path: '/products-subcategory/:id/:slug',
        element: <ChildCategoryProducts />,
      },
      {
        path: '/products-search/:slug',
        element: <SearchProducts />,
      },
      {
        path: '/product-details/:id/:slug',
        element: <ProductDetails />,
      },
      {
        path: '/cart',
        element: <ViewCart />,
      },
      {
        path: '/compare',
        element: <Compare />,
      },
      {
        path: '/checkout',
        element: <Checkout />,
      },
      {
        path: '/about',
        element: <About />,
      },
      {
        path: '/contact',
        element: <Contact />,
      },
      {
        path: '/privacy',
        element: <Privacy />,
      },
      {
        path: '/payment',
        element: <Payment />,
      },
      {
        path: '/return',
        element: <Return />,
      },
      {
        path: '/shipping-charges',
        element: <ShippingCharges />,
      },
      {
        path: '/customer-service',
        element: <CustomerService />,
      },
      {
        path: '/store-locator',
        element: <StoreLocator />,
      },
      {
        path: '/terms-condition',
        element: <TermsCondition />,
      },
      {
        path: '/size-guide',
        element: <SizeGuide />,
      },
      {
        path: '/digital-gift-voucher',
        element: <DigitalGiftVoucherPage />,
      },
      {
        path: '/success-order/:id',
        element: <SuccessOrder />,
      },
      {
        path: '/playground',
        element: <Playground />,
      },
    ],
  },
]);
