import React, { useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Title from '../component/Helmet/Title';

const Compare = () => {
  const products = [
    {
      name: 'MENS CLASSIC PANJABI',
      image: '/path/to/classic-panjabi.jpg',
      price: 3290,
      model: 'SA-EA60-HMCP-0865',
      brand: 'Sailor',
      availability: 'Out of Stock',
      rating: 0,
      summary: '',
    },
    {
      name: 'MENS FUSION PANJABI',
      image: '/path/to/fusion-panjabi.jpg',
      price: 3190,
      model: 'TM-EA60-HMFP-0700',
      brand: 'Sailor',
      availability: 'Out of Stock',
      rating: 0,
      summary: '',
    },
    {
      name: 'MENS FUSION PANJABI',
      image: '/path/to/fusion-panjabi.jpg',
      price: 3190,
      model: 'TM-EA60-HMFP-0700',
      brand: 'Sailor',
      availability: 'Out of Stock',
      rating: 0,
      summary: '',
    },
  ];

  useEffect(() => {
    // scroll top
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="mt-24 lg:mt-0">
      <Title
        title={'Compare'}
        content={'This is compare page of chader alo.'}
      />
      <h3 className="text-center my-5 lg:my-10 uppercase">Compare</h3>
      <TableContainer component={Paper} className="overflow-x-auto">
        <Table className="min-w-full">
          <TableHead>
            <TableRow>
              <TableCell className="font-bold">Product Details</TableCell>
              {products.map((product, index) => (
                <TableCell key={index} className="relative">
                  {product.name}
                  <IconButton
                    size="small"
                    className="absolute top-0 right-0"
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(products[0]).map(
              (key, index) =>
                key !== 'name' && (
                  <TableRow key={index}>
                    <TableCell
                      component="th"
                      scope="row"
                      className="font-semibold"
                    >
                      {key.charAt(0).toUpperCase() + key.slice(1)}
                    </TableCell>
                    {products.map((product, index) => (
                      <TableCell key={index}>
                        {key === 'image' ? (
                          <img
                            src={
                              'https://sailors3bucket1.s3.ap-southeast-1.amazonaws.com/uploads/all/gwhY2kEAR9YGhbUNdB6KSf9wkmh1UK4DArckLdUe.jpg'
                            }
                            alt={product.name}
                            className="w-24 h-auto"
                          />
                        ) : (
                          product[key]
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Compare;
