import {
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React from 'react';

const CartSkeleton = () => {
  return (
    <div className="my-24 lg:my-10">
      <h3 className="my-5 lg:my-10 text-center">
        <Skeleton width={100} />
      </h3>
      <div className="px-4">
        {/* cart for small device */}
        <div className="block md:hidden">
          <div>
            {[1, 2].map((r, index) => (
              <div
                className="flex items-start gap-5 mx-auto border p-2 my-2"
                key={index}
              >
                <div className="w-1/4 h-[115px] bg-secondary-200 rounded-md"></div>
                <div className="w-2/3">
                  <Skeleton width={80} height={30} />
                  <Skeleton width={50} />
                  <div className="flex items-center gap-5 justify-center mx-auto my-2">
                    <Skeleton width={20} height={20} />
                    <Skeleton width={20} height={20} />
                    <Skeleton width={20} height={20} />
                  </div>
                </div>
                <div className="">
                  <Skeleton width={30} height={30} />
                </div>
              </div>
            ))}
          </div>

          <div className="mt-4 space-y-2">
            <Skeleton width={100} height={50} className="mx-auto" />
            <Skeleton width={100} height={50} className="mx-auto" />
          </div>
        </div>

        {/* cart for large device */}
        <div className="hidden md:block">
          <div>
            <div className="w-full  mx-auto shadow-xl">
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Skeleton width={80} />
                      </TableCell>
                      <TableCell>
                        <Skeleton width={80} />
                      </TableCell>
                      <TableCell>
                        <Skeleton width={80} />
                      </TableCell>
                      <TableCell>
                        <Skeleton width={80} />
                      </TableCell>
                      <TableCell>
                        <Skeleton width={80} />
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {[1, 2].map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Skeleton width={100} height={100} />
                        </TableCell>
                        <TableCell>
                          <Skeleton width={250} />
                        </TableCell>
                        <TableCell align="left">
                          <div className="flex items-center gap-5">
                            <Skeleton width={20} height={20} />
                            <Skeleton width={20} height={20} />
                            <Skeleton width={20} height={20} />
                          </div>
                        </TableCell>
                        <TableCell>
                          <Skeleton width={50} />
                        </TableCell>
                        <TableCell>
                          <Skeleton width={50} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
          <div className=" my-4 flex items-center justify-center gap-5">
            <Skeleton width={200} height={50} />
            <Skeleton width={200} height={50} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartSkeleton;
