import React, { useState } from 'react';
import ProductsComponent from '../Products/ProductsComponent';
import { useGetNewProductsQuery } from '../../redux/Features/ServicesApi';
import NewArrivalSkeleton from '../Skeleton/Products/NewArrivalSkeleton';

const NewArrival = () => {
  const [tab, setTab] = useState('All products');
  const Tabs = ['All products', 'Men', 'Ladies', 'Boys & Girls'];

  const { data, isLoading } = useGetNewProductsQuery();
  const men = data?.data?.filter((products) => products.cat_id === 43);
  const women = data?.data?.filter((products) => products.cat_id === 44);
  const boys_girls = data?.data?.filter((products) => products.cat_id === 42);

  return (
    <>
      {isLoading ? (
        <NewArrivalSkeleton />
      ) : (
        data?.data && (
          <div className="my-5 lg:my-10">
            <h3 className="text-center my-5 lg:my-10">New Arrival</h3>

            <div className="flex flex-wrap items-center justify-center gap-3 lg:gap-6">
              {Tabs?.map((t, index) => (
                <button
                  key={index}
                  onClick={() => setTab(t)}
                  className={`${
                    tab === t
                      ? 'bg-primary-500 text-white'
                      : 'bg-secondary-100 text-black'
                  } uppercase px-4 py-2 rounded-full lg:text-lg lg:font-medium`}
                >
                  {t}
                </button>
              ))}
            </div>

            <div className="mt-4">
              {tab === 'Men' ? (
                <div>
                  <ProductsComponent
                    grid={
                      'grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5'
                    }
                    products={men}
                    isLoading={isLoading}
                  />
                </div>
              ) : tab === 'Ladies' ? (
                <div>
                  <ProductsComponent
                    grid={
                      'grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5'
                    }
                    products={women}
                    isLoading={isLoading}
                  />
                </div>
              ) : tab === 'Boys & Girls' ? (
                <div>
                  <ProductsComponent
                    grid={
                      'grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5'
                    }
                    products={boys_girls}
                    isLoading={isLoading}
                  />
                </div>
              ) : (
                tab === 'All products' && (
                  <div>
                    <ProductsComponent
                      grid={
                        'grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5'
                      }
                      products={data?.data}
                      isLoading={isLoading}
                    />
                  </div>
                )
              )}
            </div>
          </div>
        )
      )}
    </>
  );
};

export default NewArrival;
