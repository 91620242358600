import { Button } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import CancelIcon from '@mui/icons-material/Cancel';
import { motion } from 'framer-motion';
import { useGetCategoriesQuery } from '../../redux/Features/ServicesApi';
import MenuBarSkeleton from '../Skeleton/MenuBarSkeleton';

//* Menu side bar component use for small device users
const MenuBar = ({ isOpen, setIsOpen }) => {
  // category state.
  const [category, setCategory] = useState('');

  const cancelHandler = () => {
    setIsOpen(false);
    setCategory('');
  };

  const sidebarRef = useRef();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, handleClickOutside]);

  // framer motion animation
  const container = {
    open: {
      x: 0,
      transition: { type: 'Tween', staggerChildren: 0.3, stiffness: 100 },
    },
    closed: {
      x: -300,
      transition: {
        type: 'Tween',
        stiffness: 100,
        staggerChildren: 0.1,
        staggerDirection: -1,
      },
    },
  };

  const item = {
    open: {
      opacity: 1,
      x: 0,
      transition: { type: 'Tween', stiffness: 100 },
    },
    closed: {
      opacity: 0,
      x: -20,
      transition: { type: 'Tween', stiffness: 100 },
    },
  };

  const { data, isLoading } = useGetCategoriesQuery();

  return (
    <>
      {/* check if loading show menubar/sidebar skeleton. */}
      {isLoading ? (
        <MenuBarSkeleton />
      ) : (
        <div>
          {/* Menubar/sidebar component contents */}
          <motion.div
            initial="closed"
            animate={isOpen ? 'open' : 'closed'}
            variants={container}
            className={`lg:hidden fixed top-0 left-0 w-64 h-screen z-50 `}
            ref={sidebarRef}
          >
            <div className="h-full px-3 py-4 overflow-y-auto bg-secondary-50 shadow-2xl">
              <div className="flex items-center justify-between">
                <h3 className="text-xl font-medium">Menu</h3>
                <Button variant="text" className="" onClick={cancelHandler}>
                  <CancelIcon
                    className="text-secondary-900"
                    fontSize="large"
                    fontVariant={CancelIcon}
                  />
                </Button>
              </div>
              <motion.ul className="space-y-2 font-medium">
                <motion.li variants={item}>
                  <Link
                    to="/"
                    className="block py-2 px-3 text-secondary-900 border rounded md:bg-transparent"
                    aria-current="page"
                  >
                    Home
                  </Link>
                </motion.li>
                {/* categories */}
                {data?.data?.map((c, index) => (
                  <motion.li variants={item} key={index} className="">
                    {/* check if category equal c?.name then expand the subcategories of specific category. */}
                    {category === c?.name ? (
                      <button
                        type="button"
                        className="flex items-center w-full p-2 border text-base text-secondary-900 transition duration-75 rounded group hover:bg-secondary-100  "
                        onClick={() => setCategory('empty')}
                      >
                        <span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap">
                          {c?.name}
                        </span>
                        <svg
                          className="w-3 h-3"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 10 6"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m1 1 4 4 4-4"
                          />
                        </svg>
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="flex items-center w-full p-2 border text-base text-secondary-900 transition duration-75 rounded group hover:bg-secondary-100  "
                        onClick={() => setCategory(c?.name)}
                      >
                        <span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap">
                          {c?.name}
                        </span>
                        <svg
                          className="w-3 h-3"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 10 6"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m1 1 4 4 4-4"
                          />
                        </svg>
                      </button>
                    )}
                    {/* subcategories */}
                    <ul
                      id="dropdown-example"
                      className={`${
                        category === c?.name ? 'block' : 'hidden'
                      } py-2 space-y-2`}
                    >
                      <motion.li variants={item} className="border rounded-md">
                        <Link
                          to={`/products-category/${c?.name}`}
                          className="flex items-center w-full p-2 text-secondary-900 transition duration-75 rounded pl-11 group hover:bg-secondary-100"
                        >
                          {c?.name}
                        </Link>
                      </motion.li>
                      {c?.subcategories?.map((sub, index) => (
                        <motion.li
                          variants={item}
                          key={index}
                          className="border rounded-md"
                        >
                          <Link
                            to={`/products-category/${sub?.name}`}
                            className="flex items-center w-full p-2 text-secondary-900 transition duration-75 rounded pl-11 group hover:bg-secondary-100"
                          >
                            {sub?.name}
                          </Link>
                        </motion.li>
                      ))}
                    </ul>
                  </motion.li>
                ))}
              </motion.ul>
            </div>
          </motion.div>
        </div>
      )}
    </>
  );
};

export default MenuBar;
