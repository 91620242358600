import { Button, Skeleton } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';

//* Menu side bar component use for small device users
const MenuBarSkeleton = ({ isOpen, setIsOpen }) => {
  const [category, setCategory] = useState('');

  const cancelHandler = () => {
    setIsOpen(false);
    setCategory('');
  };

  // framer motion animation
  const container = {
    open: {
      x: 0,
      transition: { type: 'Tween', staggerChildren: 0.3, stiffness: 100 },
    },
    closed: {
      x: -300,
      transition: {
        type: 'Tween',
        stiffness: 100,
        staggerChildren: 0.1,
        staggerDirection: -1,
      },
    },
  };

  const item = {
    open: {
      opacity: 1,
      x: 0,
      transition: { type: 'Tween', stiffness: 100 },
    },
    closed: {
      opacity: 0,
      x: -20,
      transition: { type: 'Tween', stiffness: 100 },
    },
  };

  return (
    <div>
      {/* Menu sidebar component contents */}
      <motion.div
        initial="closed"
        animate={isOpen ? 'open' : 'closed'}
        variants={container}
        className={`lg:hidden fixed top-0 left-0 w-64 h-screen z-50 `}
      >
        <div className="h-full px-3 py-4 overflow-y-auto bg-secondary-50 shadow-2xl">
          <div className="flex items-center justify-between">
            <h3 className="text-xl font-medium">
              <Skeleton width={60} height={30} />
            </h3>
            <Skeleton width={40} height={40} />
          </div>
          <motion.ul className="space-y-2 font-medium">
            <motion.li variants={item}>
              <Skeleton width={60} />
            </motion.li>
            <motion.li variants={item}>
              <Skeleton width={60} />
            </motion.li>
            <motion.li variants={item}>
              <Skeleton width={60} />
            </motion.li>
            <motion.li variants={item}>
              <Skeleton width={60} />
            </motion.li>
          </motion.ul>
        </div>
      </motion.div>
    </div>
  );
};

export default MenuBarSkeleton;
