import React from 'react';
import ProductsComponent from '../../component/Products/ProductsComponent';
import { useParams } from 'react-router-dom';
import { useSearchWiseProductsQuery } from '../../redux/Features/ServicesApi';

const SearchProducts = () => {
    const {slug} = useParams()

    const {data, isLoading} = useSearchWiseProductsQuery(slug)
  

    return (
        <div className="my-10">
            <ProductsComponent isLoading={isLoading} products={data?.data}  grid={"grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5"}/>
        </div>
    );
};

export default SearchProducts;