import { Card, CardActionArea, CardContent, CardMedia } from '@mui/material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CompareIcon from '@mui/icons-material/Compare';
import ProductsSkeleton from '../Skeleton/Products/ProductsSkeleton';

const RecentlyProducts = ({ products, grid, isLoading }) => {
  const [isHover, setIsHover] = useState(false);
  const [productId, setProductId] = useState(null);

  const hoverHandler = (id) => {
    setIsHover(true);
    setProductId(id);
  };

  const container = {
    open: {
      y: -130,
      opacity: 1,
      transition: { type: 'Tween', duration: 0.4 },
    },
    closed: {
      y: 50,
      opacity: 0,
      transition: { type: 'Tween', duration: 0.4 },
    },
  };

  const handler = () => {
    console.log('click');
  };

  return (
    <>
      {isLoading ? (
        <ProductsSkeleton />
      ) : (
        <div className={`${grid} items-center gap-5`}>
          {products?.map((p, index) => (
            <div
              key={index}
              onMouseEnter={() => hoverHandler(p?.id)}
              onMouseLeave={() => setIsHover(false)}
            >
              <Card sx={{ maxWidth: 1000 }} className="my-2 relative">
                <Link to={`/product-details/${p?.product?.slug}`}>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      image={p?.product?.imageUrl}
                      alt="Men"
                    />
                    <CardContent>
                      <div>
                        <p className="text-lg text-secondary-500 h-14">
                          {p?.product?.name?.length > 16
                            ? `${p?.product?.name.slice(0, 16)}...`
                            : p?.product?.name}
                        </p>
                        <p>
                          ৳ {Number(p?.product?.regular_price).toFixed()} TK.
                        </p>
                        <div className="absolute z-30 bottom-5 rounded-md bg-white left-0 right-0 mx-4"></div>
                      </div>
                    </CardContent>
                  </CardActionArea>
                </Link>

                {/* framer motion action */}
                <motion.div
                  className={`${
                    productId === p?.id ? 'block' : 'hidden'
                  } flex items-center z-30 justify-center cursor-pointer gap-5 bg-secondary-50 w-44 lg:w-64 absolute right-0 left-0 mx-auto py-2 rounded-md`}
                  variants={container}
                  initial="closed"
                  animate={isHover ? 'open' : 'closed'}
                >
                  <div onClick={() => handler()}>
                    <ShoppingCartIcon />
                  </div>
                  <div>
                    <CompareIcon />
                  </div>
                </motion.div>
              </Card>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default RecentlyProducts;
