import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Define a service using a base URL and expected endpoints
export const Api = createApi({
  reducerPath: 'Api',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://cmp.chaderalo.com/api',
  }),
  tagTypes: ['products', 'carts', 'orders', 'categories'],
  endpoints: () => ({}),
});
