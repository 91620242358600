import React from 'react';
import { Link } from 'react-router-dom';

const CategoriesModal = ({ setOpen, subcategories }) => {
  return (
    <div
      className=" bg-white p-4 mx-auto border "
      onMouseLeave={() => setOpen(false)}
    >
      <div className="">
        <div className="flex items-start justify-between mx-auto p-6">
          {/* <img
            src={subcategories?.left_banner_url}
            alt={subcategories?.name}
            className="h-auto"
          /> */}
          <div className="grid grid-cols-3 2xl:grid-cols-5 px-8 mx-auto w-full justify-between">
            {subcategories?.subcategories?.map((sub, index) => (
              <div key={index}>
                <Link
                  to={`/products-category/${sub?.slug}`}
                  className="font-medium"
                >
                  {sub?.name}
                </Link>
                {sub?.sub_sub_category?.map((child, index) => (
                  <ul key={index} className="">
                    <li>
                      <Link
                        to={`/products-subcategory/${sub?.id}/${child?.slug}`}
                      >
                        {child?.name}
                      </Link>
                    </li>
                  </ul>
                ))}
              </div>
            ))}
          </div>
          <img
            src={subcategories?.right_banner_url}
            alt={subcategories?.name}
            className="h-auto"
          />
        </div>
      </div>
    </div>
  );
};

export default CategoriesModal;
