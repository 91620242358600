import { Card, CardActionArea, CardContent, CardMedia } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import AllCategoriesSkeleton from '../Skeleton/AllCategoriesSkeleton';

// categories component for only small devices.
const CategoriesComponent = ({ categories, isLoading }) => {
  return (
    <div className="">
      {isLoading ? (
        // This is loader skeleton of categories
        <AllCategoriesSkeleton />
      ) : (
        // dynamic categories from backend
        <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 items-center justify-center gap-4 mx-auto">
          {categories.map((c, index) => (
            <div key={index}>
              <Card sx={{ maxWidth: 1000 }} className="my-2 relative">
                <Link to={`/products-category/${c?.slug}`}>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      image={c?.imageUrl}
                      alt={c?.name}
                      className="h-56 md:h-96"
                    />
                    <CardContent className="absolute bottom-5 rounded-md bg-white left-0 right-0 mx-4 border shadow-lg">
                      <h5 className="text-center">{c?.name}</h5>
                    </CardContent>
                  </CardActionArea>
                </Link>
              </Card>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CategoriesComponent;
