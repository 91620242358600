import React, { useEffect, useRef, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation, Autoplay } from 'swiper/modules';
import ProductsComponent from '../../component/Products/ProductsComponent';
import SizeGuide from '../../component/Products/SizeGuide';
import Title from '../../component/Helmet/Title';
import { useParams } from 'react-router-dom';
import {
  useAddToCardDetailsMutation,
  useProductDetailsQuery,
} from '../../redux/Features/ServicesApi';
import swal from 'sweetalert';
import axios from 'axios';
import RecentlyProducts from '../../component/Products/RecenltyProducts';
import DOMPurify from 'dompurify';
import QuickOrder from '../../component/Products/QuickOrder';
import { useForm } from 'react-hook-form';
import DetailsPageSkeleton from '../../component/Skeleton/Products/DetailsPageSkeleton';
import { Button } from '@mui/material';
//* This is product details page
const ProductDetails = () => {
  const { slug, id } = useParams();
  const [ip, setIp] = useState('127.0.0.1');
  const { data, isLoading } = useProductDetailsQuery({ slug, id });

  const product = data?.data?.product;
  const related = data?.data?.related;
  const recentlyViewed = data?.data?.recentlyViewed;
  const [selectedColor, setSelectedColor] = useState();
  const [selectedSize, setSelectedSize] = useState();

  // react hook form.
  const { register, handleSubmit } = useForm();

  // set state of image
  const [image, setImage] = useState(`${product?.product_images[0]?.imageUrl}`);

  // is image full screen or not
  const [isFullScreen, setIsFullScreen] = useState(false);

  // set state magnifier position
  const [magnifierPosition, setMagnifierPosition] = useState({ x: 0, y: 0 });

  // image ref
  const imageRef = useRef(null);

  // Handle image click
  const handleImageClick = () => {
    setIsFullScreen(true);
  };

  // handle close full screen image
  const handleCloseFullScreen = () => {
    setIsFullScreen(false);
  };

  // handle magnify image
  const handleMouseMove = (e) => {
    if (!imageRef.current) return;
    const { left, top, width, height } =
      imageRef.current.getBoundingClientRect();
    const x = ((e.clientX - left) / width) * 100;
    const y = ((e.clientY - top) / height) * 100;
    setMagnifierPosition({ x, y });
  };

  useEffect(() => {
    // Scroll to the top
    window.scrollTo(0, 0);
    setImage(product?.product_images[0]?.imageUrl);
    // Fetch IP address and set it to the state
    const fetchIpAddress = async () => {
      try {
        const response = await axios.get('https://api.ipify.org?format=json');
        setIp(response.data.ip);
      } catch (error) {
        // console.error("Error fetching IP address:", error);
        setIp('127.0.0.1');
      }
    };
    fetchIpAddress();
  }, [product]);

  // useEffect(() => {
  //   const productDetailsHandler = async () => {
  //     if (ip) {
  //       const ip_address = {
  //         ip_address: ip,
  //       };
  //       try {
  //         const res = await productDetails({
  //           ip_address: ip_address,
  //           slug: slug,
  //         });

  //         setProduct(res?.data?.product);
  //         setRecentlyViewed(res?.data?.recentlyViewed);
  //         setRelated(res?.data?.related);
  //         console.log("Product details response:", res);
  //       } catch (err) {
  //         console.error("Failed to fetch product details:", err);
  //       }
  //     }
  //   };
  //   console.log(product, ip, slug);
  //   productDetailsHandler();

  //   setImage(product?.product_images[0]?.imageUrl);
  // }, [ip, slug, productDetails, setImage, product]);

  const description = DOMPurify.sanitize(product?.long_description);

  // mutation
  const [addToCart, { isLoading: cartIsLoading }] =
    useAddToCardDetailsMutation();

  const onSubmit = async (data) => {
    const order = {
      ip_address: ip,
      qty: '1',
      price: Number(product?.regular_price).toFixed(),
      color: data?.colors,
      size: data?.sizes,
    };
    try {
      const res = await addToCart({ data: order, id: product?.id }).unwrap();

      if (res?.success) {
        swal('Good job!', 'add to cart successfully!', 'success');
      }
    } catch (error) {
      swal('add to cart failed!', 'error');
    }

    Object.values(data).forEach(async (value) => {
      if (value !== undefined) {
      } else {
        alert('Please select all properties.');
        return;
      }
    });
  };

  return (
    <div className="lg:px-20 mx-auto">
      {isLoading ? (
        <DetailsPageSkeleton />
      ) : (
        <div className="my-5 lg:my-10">
          <Title
            title={`${slug} details`}
            content={`This is ${slug} product details page of chader alo.`}
          />
          <div>
            <div className="flex flex-col md:flex-row">
              <div className="md:w-1/2 relative">
                {/* Main image of product*/}
                <div
                  className="relative cursor-zoom-in"
                  onClick={handleImageClick}
                >
                  <img src={image} alt={product?.name} className=" " />
                </div>

                {/* image Thumbnails of product*/}
                <Swiper
                  slidesPerView={4}
                  loop={true}
                  spaceBetween={10}
                  breakpoints={{
                    640: {
                      slidesPerView: 4,
                      spaceBetween: 10,
                    },
                    768: {
                      slidesPerView: 5,
                      spaceBetween: 15,
                    },
                    1024: {
                      slidesPerView: 8,
                      spaceBetween: 20,
                    },
                  }}
                  modules={[Navigation, Autoplay]}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  className=" mt-4 space-x-2 mySwiper cursor-pointer"
                >
                  {product?.product_images?.map((images, index) => (
                    <SwiperSlide key={index}>
                      <img
                        onClick={() => setImage(images?.imageUrl)}
                        src={images?.imageUrl}
                        alt={product.name}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>

              {/* Product information  */}
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="md:w-1/2 md:pl-8 mt-8 md:mt-0 "
              >
                <h1 className="text-3xl font-bold text-center md:text-left">
                  {product?.name}
                </h1>
                <p className="text-sm text-secondary-600">
                  SKU: {product?.product_code}
                </p>

                <h2 className="text-2xl font-semibold mt-4">
                  Tk {Number(product?.regular_price).toFixed()}
                </h2>
                {/* colors */}
                <div className="my-4">
                  {selectedColor && (
                    <p className="font-medium">Color: {selectedColor}</p>
                  )}

                  <div className="flex space-x-2 mt-2">
                    {/* Colors of product */}
                    {product?.colors &&
                      product?.colors?.map(
                        (color, index) =>
                          color?.color && (
                            <div key={index} className="cursor-pointer">
                              <input
                                type="radio"
                                value={color?.color}
                                id={color?.color}
                                {...register('colors')}
                                className="hidden"
                              />
                              <label
                                htmlFor={color?.color}
                                onClick={() => setSelectedColor(color.color)}
                                className={`px-3 py-1 border rounded ${
                                  selectedColor === color.color
                                    ? 'bg-secondary-200'
                                    : ''
                                }`}
                              >
                                {color?.color}
                              </label>
                            </div>
                          )
                      )}
                  </div>
                </div>
                {/* sizes */}
                <div className="my-4">
                  {selectedSize && (
                    <p className="font-medium">Size: {selectedSize}</p>
                  )}
                  <div className="flex space-x-2 mt-2">
                    {/* Sizes of product */}
                    {product?.sizes?.map(
                      (size, index) =>
                        size.size && (
                          <div key={index} className="cursor-pointer">
                            <input
                              type="radio"
                              value={size?.size}
                              id={size?.size}
                              {...register('sizes')}
                              className="hidden"
                            />
                            <label
                              htmlFor={size?.size}
                              onClick={() => setSelectedSize(size?.size)}
                              className={`px-3 py-1 border rounded ${
                                selectedSize === size?.size
                                  ? 'bg-secondary-200'
                                  : ''
                              }`}
                            >
                              {size?.size}
                            </label>
                          </div>
                        )
                    )}
                  </div>
                </div>

                {/* size guide modal */}
                <div>
                  <SizeGuide image={product?.size_guide_image_url} />
                </div>

                {/* add to cart and buy now buttons */}
                {1 > 0 ? (
                  <div>
                    <div className="my-5">
                      {cartIsLoading ? (
                        <button
                          disabled
                          className="w-full bg-secondary-950 text-white mt-6 inline-flex items-center justify-center"
                        >
                          {/* Loading spinner */}
                          <svg
                            aria-hidden="true"
                            role="status"
                            className="inline w-4 h-4 me-3 text-white animate-spin"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="#E5E7EB"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentColor"
                            />
                          </svg>
                          Loading...
                        </button>
                      ) : (
                        <Button
                          variant="contained"
                          type="submit"
                          className="w-full"
                        >
                          ADD TO CART
                        </Button>
                      )}
                    </div>

                    <QuickOrder
                      color={selectedColor}
                      id={product?.id}
                      ip={ip}
                      price={Number(product?.regular_price).toFixed()}
                      size={selectedSize}
                    />
                  </div>
                ) : (
                  <div>
                    <button
                      type="button"
                      className="w-full bg-secondary-950 text-white py-3 mt-6"
                    >
                      Stocked Out
                    </button>
                  </div>
                )}

                {/* Product description */}
                <div className="mt-8">
                  <h3 className="text-xl font-semibold">Description</h3>
                  <p className="mt-2 text-secondary-700">
                    <div dangerouslySetInnerHTML={{ __html: description }} />
                  </p>
                </div>
              </form>
            </div>

            {/* If click on image show the specific image full screen*/}
            <div className="hidden md:block">
              {isFullScreen && (
                <div
                  className="fixed inset-0 bg-black bg-opacity-90 z-50 flex items-center  justify-center"
                  onClick={() => handleCloseFullScreen()}
                >
                  <button
                    className="absolute top-4 right-4 text-white text-2xl z-60"
                    onClick={() => handleCloseFullScreen()}
                  >
                    <FaTimes />
                  </button>
                  <div
                    className="relative w-full h-full"
                    onMouseMove={handleMouseMove}
                    style={{ cursor: 'none' }}
                  >
                    <img
                      ref={imageRef}
                      src={image}
                      alt={product.name}
                      id="myimage"
                      className="w-full h-full object-contain"
                    />

                    {/* Magnify image when hover */}
                    <div
                      className="absolute pointer-events-none"
                      style={{
                        left: `${magnifierPosition.x}%`,
                        top: `${magnifierPosition.y}%`,
                        width: '200px',
                        height: '200px',
                        transform: 'translate(-50%, -50%)',
                        border: '2px solid white',
                        borderRadius: '50%',
                        backgroundImage: `url('${image}')`,
                        backgroundPosition: `${magnifierPosition.x}% ${magnifierPosition.y}%`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: '1000%',
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Related and recently viewed products. */}
          {related?.length > 0 && (
            <div className="my-3 lg:my-6">
              <h4 className=" uppercase my-5 lg:my-10">Related Products.</h4>
              <ProductsComponent
                grid={
                  'grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5'
                }
                products={related}
                isLoading={isLoading}
              />
            </div>
          )}
          {recentlyViewed?.length > 0 && (
            <div>
              <h4 className="my-3 uppercase lg:my-6">Recently viewed</h4>
              <RecentlyProducts
                grid={
                  'grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5'
                }
                products={recentlyViewed}
                isLoading={isLoading}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ProductDetails;
