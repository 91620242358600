import React from 'react';
import { Facebook, Instagram, Youtube } from 'lucide-react';
import { useGeneralDataQuery } from '../../redux/Features/ServicesApi';
import { Link } from 'react-router-dom';
import { BsTwitterX } from 'react-icons/bs';
const Footer = () => {
  const { data: generalData } = useGeneralDataQuery();

  const year = new Date().getFullYear();

  return (
    <footer className="bg-secondary-900 text-white py-8">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* Contact Info */}
          <div>
            <h3 className="text-lg font-semibold mb-4 text-white">
              CONTACT INFO
            </h3>
            <p className="mb-2">{generalData?.generalData?.address}</p>
            <p className="mb-2">{generalData?.generalData?.email}</p>
            <p className="mb-2">{generalData?.generalData?.phone}</p>
            <div className="flex space-x-4 mt-4">
              <Link to={`${generalData?.generalData?.facebook}`}>
                <Facebook size={20} />
              </Link>
              <Link to={`${generalData?.generalData?.instagram}`}>
                <Instagram size={20} />
              </Link>

              <Link to={`${generalData?.generalData?.youtube}`}>
                <Youtube size={20} />
              </Link>
              <Link to={`${generalData?.generalData?.twitter}`}>
                <BsTwitterX size={20} />
              </Link>
            </div>
            <div className="mt-6">
              <img
                src="https://cmp.chaderalo.com/setting/1727683560.jpeg"
                alt=""
              />
            </div>
          </div>

          {/* Know Us */}
          <div>
            <h3 className="text-lg font-semibold mb-4 text-white">KNOW US</h3>
            <ul className="space-y-2">
              <li>
                <Link to={'/about'}>Who We Are</Link>
              </li>
              <li>
                <Link to={'/'}>Join Us</Link>
              </li>
            </ul>
          </div>

          {/* Shopping Information */}
          <div>
            <h3 className="text-lg font-semibold mb-4 text-white">
              SHOPPING INFORMATION
            </h3>
            <ul className="space-y-2">
              <li>
                <Link to={'/privacy'}>Privacy Policy</Link>
              </li>
              <li>
                <Link to={'/size-guide'}>Size Guide</Link>
              </li>
              <li>How To Shop</li>
              <li>
                <Link to={'/digital-gift-voucher'}>
                  Digital Gift Voucher T&C
                </Link>
              </li>
            </ul>
          </div>

          {/* Service Information */}
          <div>
            <h3 className="text-lg font-semibold mb-4 text-white">
              SERVICE INFORMATION
            </h3>
            <ul className="space-y-2">
              <li>
                <Link to={'/return'}>Return And Exchange</Link>
              </li>
              <li>
                <Link to={'/shipping-charges'}>Shipping & Charges</Link>
              </li>
              <li>
                <Link to={'/customer-service'}>Customer Service</Link>
              </li>
              <li>
                <Link to={'/terms-condition'}>Terms And Conditions</Link>
              </li>
              <li>
                <Link to={'/store-locator'}>Store Locator</Link>
              </li>
            </ul>
          </div>
        </div>

        {/* Copyright */}
        <div className="mt-8 pt-8 border-t  flex flex-col md:flex-row justify-between items-center">
          <p>&copy; {year} Chader alo. All Rights Reserved</p>
          <p>
            System Design & Developed By :{' '}
            <a href="https://webcoder-it.com" className="text-red-500">
              Webcoder-IT.
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
