import { Card, CardActionArea, CardContent, Skeleton } from '@mui/material';
import React from 'react';

const AllCategoriesSkeleton = () => {
  return (
    <div>
      {/* Categories component */}
      <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 items-center justify-center gap-4 mx-auto">
        {[1, 2, 3, 4, 5, 6].map((c, index) => (
          <div key={index}>
            <Card sx={{ maxWidth: 1000 }} className="my-2 relative">
              <CardActionArea>
                <div className="h-96 bg-secondary-200 animate-pulse rounded-md"></div>
                <CardContent className="absolute z-30 bottom-5 rounded-md bg-secondary-200 left-0 right-0 mx-4 border shadow-lg">
                  <Skeleton />
                </CardContent>
              </CardActionArea>
            </Card>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AllCategoriesSkeleton;
