import React, { useEffect } from 'react';
import CategoriesComponent from '../component/Categories/CategoriesComponent';
import Title from '../component/Helmet/Title';
import { useGetCategoriesQuery } from '../redux/Features/ServicesApi';

//* This is categories page of small devices.
const Categories = () => {
  // get categories from backend using RTK query
  const { data, isLoading } = useGetCategoriesQuery();

  // always open page from top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="mt-20 block lg:hidden">
      {/* React helmet */}
      <Title
        title={'Categories'}
        content={'This is categories page of chader alo.'}
      />
      <h3 className="text-center my-5 lg:my-10 uppercase">All Categories.</h3>
      {/* Categories component */}
      <CategoriesComponent isLoading={isLoading} categories={data?.data} />
    </div>
  );
};

export default Categories;
