import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

export default function SizeGuide({ image }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button
        variant="outlined"
        style={{ color: 'black', border: '1px solid black' }}
        onClick={handleClickOpen}
      >
        Size Guide
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Size Guide</DialogTitle>
        <DialogContent>
          <img src={image} alt="size guide" className="w-96" />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
