import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Link } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';

//* This is cart page component.
const CartComponent = ({ products }) => {
  // quantity state.
  const [quantity, setQuantity] = useState(1);

  // quantity increase handler.
  const quantityIncreaseHandler = () => {
    if (quantity < 10) {
      const qnt = quantity + 1;
      setQuantity(qnt);
    }
  };

  // quantity decrease handler.
  const quantityDecreaseHandler = () => {
    if (quantity > 1) {
      const qnt = quantity - 1;
      setQuantity(qnt);
    }
  };

  return (
    <div className="px-4">
      {/* view cart for small device */}
      <div className="block md:hidden">
        <div>
          {products?.map((product, index) => (
            <div
              className="flex items-start gap-5 mx-auto border p-2 my-2 rounded-md shadow-md"
              key={index}
            >
              <div className="w-1/4">
                <img
                  src={product?.product?.imageUrl}
                  alt={product?.product?.name}
                  className="size-24 rounded-md"
                />
              </div>
              <div className="w-2/3">
                <p>{product?.product?.name}</p>
                <p className="text-bold text-xl">
                  TK. {product?.product?.regular_price}
                </p>
                <div className="flex items-center gap-5 justify-center mx-auto my-2">
                  <button
                    variant="outlined"
                    onClick={() => quantityDecreaseHandler()}
                  >
                    <RemoveIcon className="bg-secondary-300 rounded-full" />
                  </button>
                  <p>{product?.qty}</p>
                  <button
                    variant="outlined"
                    onClick={() => quantityIncreaseHandler()}
                  >
                    <AddIcon className="bg-secondary-300 rounded-full" />
                  </button>
                </div>
              </div>
              <div className="">
                <button>
                  <DeleteIcon className="text-red-500 bg-red-300 rounded-full" />
                </button>
              </div>
            </div>
          ))}
        </div>

        <div className="mt-4 space-y-2">
          <div className="text-center bg-secondary-950 text-white py-2 rounded-md">
            <Link
              to={'/checkout'}
              className={`w-full mx-auto ${
                products?.length > 0 ? '' : 'disabled'
              }`}
            >
              Proceed to checkout
            </Link>
          </div>
          <div className="text-center bg-black text-white py-2 rounded-md">
            <Link to={'/'} className="w-full mx-auto ">
              Continue Shopping
            </Link>
          </div>
        </div>
      </div>

      {/*view cart for large device */}
      <div className="hidden md:block">
        <div>
          <div className="w-full mx-auto shadow-xl">
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Image</TableCell>
                    <TableCell>Product</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {products?.map((product, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <img
                          src={product?.product?.imageUrl}
                          alt={product?.product?.name}
                          className="size-24 rounded-md"
                        />
                      </TableCell>
                      <TableCell>
                        <p>{product?.product?.name}</p>
                      </TableCell>
                      <TableCell align="left">
                        <div className="flex items-center gap-5">
                          <button
                            variant="outlined"
                            onClick={() => quantityDecreaseHandler()}
                          >
                            <RemoveIcon className="bg-secondary-300 rounded-full" />
                          </button>
                          <p>{product?.qty}</p>
                          <button
                            variant="outlined"
                            onClick={() => quantityIncreaseHandler()}
                          >
                            <AddIcon className="bg-secondary-300 rounded-full" />
                          </button>
                        </div>
                      </TableCell>
                      <TableCell>
                        {Number(product?.product?.regular_price).toFixed()} TK.
                      </TableCell>
                      <TableCell>
                        {Number(product?.product?.regular_price).toFixed() *
                          Number(product?.qty)}{' '}
                        {''}
                        TK.
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
        {/* Action button */}
        <div className=" my-4 flex items-center justify-center gap-5">
          <button className="text-center border-black border text-black p-2 rounded-md">
            <Link to={'/'} className=" ">
              Continue Shopping
            </Link>
          </button>
          <button className="text-center bg-secondary-950 text-white p-2 rounded-md">
            <Link
              to={'/checkout'}
              className={`w-full mx-auto ${
                products?.length > 0 ? '' : 'disabled'
              }`}
            >
              Proceed to checkout
            </Link>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CartComponent;
