import React from 'react';
import CategoriesDropdown from '../ui/CategoriesDropdown';
import { useGetCategoriesQuery } from '../../redux/Features/ServicesApi';
import { useNavigate } from 'react-router-dom';

// search field for large devices.
const SearchField = ({ width, isCategoryOpen, setIsCategoryOpen }) => {
  const navigate = useNavigate();
  // categories query.
  const { data } = useGetCategoriesQuery();

  // form handler
  const searchHandle = (e) => {
    e.preventDefault();

    navigate(`/products-search/${e.target.search.value}`);
  };

  return (
    <div className=" relative">
      <form className="max-w-screen-xl mx-auto" onSubmit={searchHandle}>
        <div className="flex">
          <label
            htmlFor="search-dropdown"
            className="mb-2 text-sm font-medium text-secondary-900 sr-only "
          >
            Search
          </label>
          <button
            id="dropdown-button"
            data-dropdown-toggle="dropdown"
            className="flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-secondary-900 bg-secondary-100 border border-primary-500 rounded-s-lg hover:bg-secondary-200 focus:ring-4 focus:outline-none focus:ring-primary-100"
            type="button"
            onClick={() => setIsCategoryOpen(!isCategoryOpen)}
          >
            All categories
            <svg
              className="w-2.5 h-2.5 ms-2.5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 10 6"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 4 4 4-4"
              />
            </svg>
          </button>
          <div
            className="absolute top-10"
            onMouseLeave={() => setIsCategoryOpen(false)}
          >
            {isCategoryOpen && <CategoriesDropdown categories={data?.data} />}
          </div>
          <div className="relative w-full">
            <input
              type="search"
              id="search"
              className={`block p-2.5 ${width} z-20 text-sm text-secondary-900 bg-secondary-50 rounded-e-lg border-s-primary-50 border-s-2 border border-primary-500 focus:ring-primary-500 focus:border-primary-500 `}
              placeholder="Search..."
              required
            />
            <button
              type="submit"
              className="absolute top-0 end-0 p-2.5 text-sm font-medium h-full text-white bg-primary-500 rounded-e-lg border border-primary-500 hover:bg-primary-500 focus:ring-4 focus:outline-none focus:ring-primary-300"
            >
              <svg
                className="w-4 h-4"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
              <span className="sr-only">Search</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SearchField;
