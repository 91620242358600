import React, { useEffect, useRef, useState } from 'react';
import { Skeleton } from '@mui/material';
import MenuBarSkeleton from './MenuBarSkeleton';
import SearchField from '../HeaderFooter/SearchField';

const HeaderSkeleton = () => {
  const [isOpenSidebar, setIsOpenSidebar] = useState(false);
  const [isOpenSearch, setIsOpenSearch] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenCategoryDropdown, setOpenCategoryDropdown] = useState(false);

  window.addEventListener('scroll', function () {
    const scrollTop = window.scrollY;
    const fixedNavbar = document.getElementById('fixed-navbar');

    if (scrollTop >= 100) {
      fixedNavbar.classList.remove('hidden');
    } else {
      fixedNavbar.classList.add('hidden');
    }
  });

  const modalRef = useRef();

  // eslint-disable-next-line react-hooks/exhaustive-deps

  return (
    <div>
      {/* HeaderSkeleton for large devices */}
      <div className=" hidden lg:block">
        {/* 1st headerSkeleton */}
        <div className="border-t">
          <div className="flex items-center justify-between px-2 py-4 max-w-screen-2xl text-sm mx-auto text-secondary-800 font-medium">
            <Skeleton width={70} />
            <div className="flex items-center justify-between gap-5 ">
              <Skeleton width={50} />
              <Skeleton width={50} />
              <Skeleton width={50} />
            </div>
          </div>
        </div>
        {/* 2nd headerSkeleton */}
        <div className="border-b bg-white">
          <div className="flex items-center justify-between px-2 py-1 max-w-screen-2xl mx-auto border-y">
            <Skeleton width={90} height={90} />
            <div>
              <Skeleton width={700} height={50} />
            </div>
            <div className="flex items-center justify-between gap-2 ">
              <div>
                <Skeleton width={20} height={40} />
              </div>
              <div>
                <Skeleton width={70} />
                <Skeleton width={80} />
              </div>
            </div>
            <div className="">
              <Skeleton width={40} height={40} />
            </div>
          </div>
          <div className="my-2 relative bg-white">
            <ul className="flex items-center justify-center p-2 max-w-screen-2xl mx-auto gap-6 text-secondary-500 font-medium uppercase">
              <li>
                <Skeleton width={50} />
              </li>
              <li>
                <Skeleton width={50} />
              </li>
              <li>
                <Skeleton width={50} />
              </li>
              <li>
                <Skeleton width={50} />
              </li>
              <li>
                <Skeleton width={50} />
              </li>
              <li>
                <Skeleton width={50} />
              </li>
              <li>
                <Skeleton width={50} />
              </li>
            </ul>
          </div>
        </div>

        {/* Fixed navbar */}
        <div
          className="border-b bg-white bg-primary fixed w-full z-30 top-0 start-0 hidden"
          id="fixed-navbar"
        >
          <div className="flex items-center justify-between px-2 py-1 max-w-screen-2xl mx-auto border-y">
            <Skeleton width={90} height={90} />
            <div>
              <Skeleton width={700} height={50} />
            </div>
            <div className="flex items-center justify-between gap-2 ">
              <div>
                <Skeleton width={20} height={40} />
              </div>
              <div>
                <Skeleton width={70} />
                <Skeleton width={80} />
              </div>
            </div>
            <div>
              <div className="">
                <Skeleton width={40} height={40} />
              </div>
            </div>
          </div>
          <div className="my-2 relative bg-white">
            <ul className="flex items-center justify-center p-2 max-w-screen-2xl mx-auto gap-6 text-secondary-500 font-medium uppercase">
              <li>
                <Skeleton width={50} />
              </li>
              <li>
                <Skeleton width={50} />
              </li>
              <li>
                <Skeleton width={50} />
              </li>
              <li>
                <Skeleton width={50} />
              </li>
              <li>
                <Skeleton width={50} />
              </li>
              <li>
                <Skeleton width={50} />
              </li>
              <li>
                <Skeleton width={50} />
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* HeaderSkeleton for small & medium devices */}
      <div className="lg:hidden block fixed top-0 start-0 end-0 z-30">
        <div className=" container mx-auto px-2 border bg-secondary-50 shadow-md relative">
          <div className="flex items-center justify-between">
            <div onClick={() => setIsOpenSidebar(true)}>
              <Skeleton width={50} height={40} />
            </div>
            <div>
              <Skeleton width={90} height={60} />
            </div>
            <Skeleton width={40} height={40} />
          </div>

          {/* Menu bar */}
          <MenuBarSkeleton />

          {/* Search field */}
          {isOpenSearch && (
            <div className="absolute left-0 top-16 right-0 px-2 border">
              {isOpenSearch && (
                <SearchField
                  width={'w-[200px]'}
                  isCategoryOpen={isOpenCategoryDropdown}
                  setIsCategoryOpen={setOpenCategoryDropdown}
                />
              )}
            </div>
          )}
        </div>

        {/* fixed bottom navbar */}
        <div className="fixed bottom-0 left-0 right-0 mx-auto bg-secondary-50 z-30 p-2">
          <div className="flex items-center justify-between">
            <Skeleton width={60} height={40} />
            <Skeleton width={60} height={40} />
            <Skeleton width={60} height={40} />
            <Skeleton width={60} height={40} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderSkeleton;
