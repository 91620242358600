import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import CompareIcon from '@mui/icons-material/Compare';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import MenuBar from './MenuBar';
import SearchField from './SearchField';
import CategoriesModal from '../ui/CategoriesModal';
import { Badge } from '@mui/material';
import CartDropdown from '../Cart/CartDropdown';
import HomeIcon from '@mui/icons-material/Home';
import WidgetsIcon from '@mui/icons-material/Widgets';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import MobileSearch from './MobileSearch';
import {
  useCountCartProductsQuery,
  useGetCategoriesQuery,
  useGeneralDataQuery,
} from '../../redux/Features/ServicesApi';
import axios from 'axios';
import HeaderSkeleton from '../Skeleton/HeaderSkeleton';

const Header = () => {
  const [isOpenSidebar, setIsOpenSidebar] = useState(false);
  const [isOpenSearch, setIsOpenSearch] = useState(false);
  const [catId, setCatId] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [ip, setIp] = useState('');
  const [isOpenCategoryDropdown, setOpenCategoryDropdown] = useState(false);
  const [isOpenCart, setIsOpenCart] = useState(false);

  // scroll eventHandler
  window.addEventListener('scroll', function () {
    const scrollTop = window.scrollY;
    const fixedNavbar = document.getElementById('fixed-navbar');

    if (scrollTop >= 100) {
      fixedNavbar.classList.remove('hidden');
    } else {
      fixedNavbar.classList.add('hidden');
    }
  });

  const modalRef = useRef();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setIsOpenModal(false);
    }
  };

  useEffect(() => {
    //  Fetch IP address and set it to the state
    const fetchIpAddress = async () => {
      try {
        const response = await axios.get('https://api.ipify.org?format=json');
        setIp(response.data.ip);
      } catch (error) {
        console.error('Error fetching IP address:', error);
        setIp('127.0.0.1');
      }
    };
    fetchIpAddress();

    if (isOpenModal) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside, isOpenModal]);

  // categories data query
  const { data, isLoading } = useGetCategoriesQuery();
  // cart products count query
  const { data: count, isLoading: countLoading } =
    useCountCartProductsQuery(ip);
  // general data query
  const { data: generalData, isLoading: generalLoading } =
    useGeneralDataQuery();

  const handleModal = (id) => {
    setCatId(id);
    setIsOpenModal(true);
  };

  const findCategories = data?.data?.find((c) => c?.id === catId);

  return (
    <>
      {/* check is loading show skeleton. */}
      {isLoading || countLoading || generalLoading ? (
        <HeaderSkeleton />
      ) : (
        <div className="mb-16 lg:mb-2">
          {/* Header for large devices */}
          <div className=" hidden lg:block">
            {/* 1st header */}
            <div className="border-t">
              <div className="flex items-center justify-between px-2 py-4 max-w-screen-2xl text-sm mx-auto text-secondary-800 font-medium">
                <Link to={'/'}>Chader alo</Link>
                <div className="flex items-center justify-between gap-5 ">
                  <Link to={'/about'}>About</Link>
                  <Link to={'/cart'}>Cart</Link>
                  <Link to={'/compare'}>Compare</Link>
                </div>
              </div>
            </div>
            {/* 2nd header */}
            <div className="border-b bg-white">
              <div className="flex items-center justify-between p-2 max-w-screen-2xl mx-auto border-y">
                <Link to={'/'}>
                  <img
                    src={generalData?.generalData?.logo_url}
                    alt="Logo"
                    className="lg:w-40"
                  />
                </Link>
                <div>
                  <SearchField
                    width={'lg:w-[350px] xl:w-[500px]'}
                    isCategoryOpen={isOpenCategoryDropdown}
                    setIsCategoryOpen={setOpenCategoryDropdown}
                  />
                </div>
                <div className="flex items-center justify-between gap-2  ">
                  <div>
                    <LocalPhoneIcon style={{ color: '#6d6d6d' }} />
                  </div>
                  <div>
                    <p className="text-secondary-600 text-sm">Call Us Now</p>
                    <a
                      href={`tel:${generalData?.generalData?.phone}`}
                      className="text-secondary-800"
                    >
                      {generalData?.generalData?.phone}
                    </a>
                  </div>
                </div>
                <div className="">
                  <div
                    className="relative inline-block text-left"
                    onMouseLeave={() => setIsOpenCart(false)}
                  >
                    <button
                      className="bg-white p-2 rounded-full hover:bg-gray-100 focus:outline-none"
                      onMouseEnter={() => setIsOpenCart(true)}
                    >
                      <svg
                        className="h-6 w-6 text-gray-700"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                        />
                      </svg>
                      <span className="absolute top-0 start-0 inline-flex items-center justify-center px-1 py-0.5 text-xs font-bold leading-none text-secondary-50 transform translate-x-1/2 -translate-y-1/2 bg-secondary-950 rounded-full">
                        {count?.data}
                      </span>
                    </button>
                    <div className="origin-top-right absolute right-0 w-80 rounded-md shadow-lg bg-white focus:outline-none z-30">
                      <CartDropdown
                        isOpen={isOpenCart}
                        setIsOpen={setIsOpenCart}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="my-2 relative bg-primary-500"
                onMouseLeave={() => setIsOpenModal(false)}
              >
                <ul className="flex items-center justify-center p-2 max-w-screen-2xl mx-auto gap-6 text-secondary-50 font-medium uppercase">
                  <Link to="/">Home</Link>
                  {data?.data?.map((c, index) => (
                    <li key={index} onMouseEnter={() => handleModal(c?.id)}>
                      <Link to={`/products-category/${c?.slug}`}>
                        {c?.name}
                      </Link>
                    </li>
                  ))}
                </ul>
                <div className=" top-10 shadow-md right-0 left-0 z-30 mx-auto absolute">
                  {isOpenModal && (
                    <CategoriesModal
                      setOpen={setIsOpenModal}
                      subcategories={findCategories}
                    />
                  )}
                </div>
              </div>
            </div>

            {/* sticky navbar for large devices*/}
            <div
              className="border-b py-1 bg-white bg-primary fixed w-full z-30 top-0 start-0 hidden"
              id="fixed-navbar"
            >
              <div className="flex items-center justify-between px-2 py-1 max-w-screen-2xl mx-auto border-y">
                <Link to={'/'}>
                  <img
                    src={generalData?.generalData?.logo_url}
                    alt="Logo"
                    className="w-40"
                  />
                </Link>
                <div>
                  <SearchField
                    width={'lg:w-[400px] xl:w-[500px]'}
                    isCategoryOpen={isOpenCategoryDropdown}
                    setIsCategoryOpen={setOpenCategoryDropdown}
                  />
                </div>
                <div className="flex items-center justify-between gap-2 ">
                  <div>
                    <LocalPhoneIcon style={{ color: '#6d6d6d' }} />
                  </div>
                  <div>
                    <p className="text-secondary-600 text-sm">Call Us Now</p>
                    <a
                      href={`tel: ${generalData?.generalData?.phone}`}
                      className="text-secondary-800"
                    >
                      {generalData?.generalData?.phone}
                    </a>
                  </div>
                </div>
                <div>
                  <div
                    className="relative inline-block text-left"
                    onMouseLeave={() => setIsOpenCart(false)}
                  >
                    <button
                      className="bg-white p-2 rounded-full hover:bg-gray-100 focus:outline-none"
                      onMouseEnter={() => setIsOpenCart(true)}
                    >
                      <svg
                        className="h-6 w-6 text-gray-700"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                        />
                      </svg>
                      <span className="absolute top-0 start-0 inline-flex items-center justify-center px-1 py-0.5 text-xs font-bold leading-none text-secondary-50 transform translate-x-1/2 -translate-y-1/2 bg-secondary-950 rounded-full">
                        {count?.data}
                      </span>
                    </button>
                    <div className="origin-top-right absolute right-0 w-80 rounded-md shadow-lg bg-white focus:outline-none z-30">
                      <CartDropdown
                        isOpen={isOpenCart}
                        setIsOpen={setIsOpenCart}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="my-2 relative bg-primary-500"
                onMouseLeave={() => setIsOpenModal(false)}
              >
                <ul className="flex items-center justify-center p-2 max-w-screen-2xl text-secondary-50 mx-auto gap-6 font-medium uppercase">
                  <Link to="/">Home</Link>
                  {data?.data?.map((c, index) => (
                    <li
                      key={index}
                      onMouseEnter={() => handleModal(c?.id)}
                      className=" "
                    >
                      <Link to={`/products-category/${c?.slug}`} className=" ">
                        {c?.name}
                      </Link>
                    </li>
                  ))}
                </ul>
                <div className=" top-10 shadow-md right-0 left-0 z-30 mx-auto absolute">
                  {isOpenModal && (
                    <CategoriesModal
                      setOpen={setIsOpenModal}
                      subcategories={findCategories}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Header for small & medium devices */}
          <div className="lg:hidden block fixed top-0 start-0 end-0 z-30">
            <div className=" container mx-auto px-2 py-2 border bg-secondary-50 shadow-md relative">
              <div className="flex items-center justify-between">
                <div onClick={() => setIsOpenSidebar(true)}>
                  <MenuIcon style={{ color: '#6d6d6d' }} />
                </div>
                <Link to={'/'}>
                  <img
                    src={generalData?.generalData?.logo_url}
                    alt="logo"
                    className="w-40"
                  />
                </Link>
                <div onClick={() => setIsOpenSearch(!isOpenSearch)}>
                  <SearchIcon style={{ color: '#6d6d6d' }} />
                </div>
              </div>

              {/* Menu bar */}
              <MenuBar isOpen={isOpenSidebar} setIsOpen={setIsOpenSidebar} />

              {/* Search field */}
              {isOpenSearch && (
                <div className="absolute left-0 top-0 right-0 border">
                  {isOpenSearch && <MobileSearch setOpen={setIsOpenSearch} />}
                </div>
              )}
            </div>

            {/* sticky bottom navbar for only small devices.*/}
            <div className="fixed bottom-0 left-0 right-0 mx-auto bg-secondary-50 z-30 p-2">
              <div className="flex items-center justify-between">
                <Link to={'/'} className="mx-auto text-center">
                  <HomeIcon />
                  <p>Home</p>
                </Link>
                <Link to={'/categories'} className="mx-auto text-center">
                  <WidgetsIcon />
                  <p>Categories</p>
                </Link>
                {/* <Link
                  className="mx-auto text-center"
                  to={'/compare'}
                  title="Cart"
                >
                  <Badge badgeContent={1} color="default">
                    <CompareIcon />
                  </Badge>
                  <p>Compare</p>
                </Link> */}
                <Link className="mx-auto text-center" to={'/cart'} title="Cart">
                  <Badge badgeContent={count?.data} color="default">
                    <ShoppingCartIcon />
                  </Badge>
                  <p>Cart</p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
