import React, { useEffect } from 'react';
import DOMPurify from 'dompurify';
import Title from '../../component/Helmet/Title';
import { usePrivacyPolicyQuery } from '../../redux/Features/ServicesApi';
import { Facebook, Instagram, Phone } from 'lucide-react';

const Return = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { data } = usePrivacyPolicyQuery();
  const privacy = DOMPurify.sanitize(data?.data?.privacy_policy);

  return (
    <section className="mt-24 lg:mt-0">
      <div className="bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
        <div className=" mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
          <div className="px-6 py-8">
            <h1 className="text-3xl font-bold text-center text-gray-800 mb-8">
              Return and Exchange Policy
            </h1>

            <p className="text-gray-600 mb-6">
              We always want to achieve our customer's ultimate satisfaction.
              Following this, we did our best to make a return policy with
              extended service, which is suitable for our valuable customers.
            </p>

            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              Following conditions will qualify under Return & Exchange Policy
            </h2>
            <ul className="list-disc pl-6 mb-6 text-gray-600">
              <li>
                If any of our Product failed to meet the quality parameter, such
                as:
                <ul className="list-disc pl-6 mt-2">
                  <li>Physically Damaged Product</li>
                  <li>Major Defects</li>
                  <li>Size or fitting issue</li>
                </ul>
              </li>
              <li>If Salvia delivers the wrong items to the customer</li>
              <li>Product Lost during shipment</li>
              <li>
                If any technical error occurred - Wrong Order placement, Money
                Deduction
              </li>
            </ul>

            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              How to Exchange
            </h2>
            <ol className="list-decimal pl-6 mb-6 text-gray-600">
              <li className="mb-2">
                If you face any issue with our Product, immediately call us or
                message us on our official social media accounts (within 24
                hours of receiving the product):
                <div className="flex items-center mt-2 space-x-4">
                  <span className="flex items-center">
                    <Phone size={18} className="mr-2" /> +8801898801900
                  </span>
                  <a
                    href="https://www.facebook.com/salvialifestylebd"
                    className="flex items-center text-blue-600 hover:underline"
                  >
                    <Facebook size={18} className="mr-2" /> Facebook
                  </a>
                </div>
              </li>
              <li>
                Alternatively, you can go to your nearest Salvia outlet to
                return the Product and exchange the desired one (Note: if that
                similar Product is available on that specific outlet)
              </li>
            </ol>

            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              Also please note that,
            </h2>
            <ul className="list-disc pl-6 mb-6 text-gray-600">
              <li>
                Customer needs to send us the scan copy or image of the invoice
                of the courier service used during the return process.
              </li>
              <li>
                Footwear/Bag/Jewelry Accessories/Home Décor - can be exchanged
                only in case of not being satisfied with the Product's Quality
                or if the Product is damaged or defective.
              </li>
              <li>
                After receiving the Product, Salvia has full authority to make a
                decision whether the Product will be applicable for exchange or
                refund policy.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Return;
