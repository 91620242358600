import React, { useEffect } from 'react';
import Banner from '../component/Home/Banner/Banner';
import TrendingCategories from '../component/Home/TrendingCategories/TrendingCategories';
import NewArrival from '../component/Home/NewArrival';
import PromotionalBanner from '../component/Home/PromotionalBanner';
import Title from '../component/Helmet/Title';

const Home = () => {
  useEffect(() => {
    // scroll top
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="">
      <Title title={'Home'} content={'This is Home page of chader alo.'} />
      <Banner />
      <TrendingCategories />
      <NewArrival />
      <PromotionalBanner />
    </div>
  );
};

export default Home;
