import React from 'react';
import { Link } from 'react-router-dom';

const CategoriesDropdown = ({ categories }) => {
  return (
    <>
      <div className="absolute bg-white divide-y divide-secondary-100 rounded-lg shadow w-44 z-40">
        <ul
          className="py-2 text-sm text-secondary-700 "
          aria-labelledby="dropdown-button"
        >
          {categories?.map((c, index) => (
            <li key={index}>
              <Link
                to={`/products-category/${c?.slug}`}
                className="inline-flex w-full px-4 py-2 hover:bg-secondary-100 "
              >
                {c?.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default CategoriesDropdown;
