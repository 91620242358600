import React from 'react';

const PromotionalBannerSkeleton = () => {
  return (
    <div className="my-5 lg:my-10 animate-pulse">
      <div className="lg:flex items-center justify-between gap-4 mx-auto space-y-2 lg:space-y-0">
        <div className="w-1/2 h-96 bg-secondary-200"></div>
        <div className="w-1/2 h-96 bg-secondary-200"></div>
      </div>
    </div>
  );
};

export default PromotionalBannerSkeleton;
