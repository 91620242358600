import { Skeleton } from '@mui/material';
import React from 'react';

const DetailsPageSkeleton = () => {
  return (
    <div className="flex flex-col md:flex-row my-10">
      <div className="md:w-1/2 relative">
        {/* Main image of product*/}
        <div className="relative cursor-zoom-in">
          <div className="bg-secondary-200 animate-pulse h-[500px]"></div>
        </div>
      </div>

      {/* Product information  */}
      <div className="md:w-1/2 md:pl-8 mt-8 md:mt-0 ">
        <h1 className="text-3xl font-bold text-center md:text-left">
          <Skeleton />
        </h1>
        <p className="text-sm text-secondary-600">
          <Skeleton />
        </p>

        <h2 className="text-2xl font-semibold mt-4">
          <Skeleton />
        </h2>

        {/* size guide modal */}
        <div>
          <Skeleton height={50} />
        </div>

        {/* add to cart and buy now buttons */}
        <div>
          <Skeleton height={70} />
          <Skeleton height={70} />
        </div>

        {/* Product description */}
        <div className="mt-8">
          <Skeleton />
          <p className="mt-2 text-secondary-700">
            <Skeleton height={100} />
          </p>
        </div>
      </div>
    </div>
  );
};

export default DetailsPageSkeleton;
