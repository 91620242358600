import { Skeleton } from '@mui/material';
import React from 'react';
import ProductsSkeleton from './ProductsSkeleton';

const NewArrivalSkeleton = () => {
  return (
    <div className="my-5 lg:my-10">
      <h3 className="text-center my-5 lg:my-10">
        <Skeleton width={300} className="mx-auto" />
      </h3>

      <div className="flex flex-wrap items-center justify-center gap-3 lg:gap-6">
        {[1, 2, 3, 4, 5, 6]?.map((t) => (
          <button
            key={t}
            className={`uppercase bg-secondary-100 px-4 py-2 rounded-full lg:text-lg lg:font-medium`}
          >
            <Skeleton width={80} />
          </button>
        ))}
      </div>

      <div className="mt-4">
        <div>
          <ProductsSkeleton
            grid={
              'grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5'
            }
          />
        </div>
      </div>
    </div>
  );
};

export default NewArrivalSkeleton;
