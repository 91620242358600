import React, { useEffect } from 'react';
import DOMPurify from 'dompurify';
import Title from '../../component/Helmet/Title';
import { usePrivacyPolicyQuery } from '../../redux/Features/ServicesApi';

const Payment = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { data } = usePrivacyPolicyQuery();
  const privacy = DOMPurify.sanitize(data?.data?.privacy_policy);

  return (
    <section className="mt-24 lg:mt-0">
      <Title
        title={'Payment Policy'}
        content={'This is payment policy page of chader alos.'}
      />
      <h3 className="text-center my-5 lg:my-10">Payment</h3>
      <div className="my-10">
        <div dangerouslySetInnerHTML={{ __html: privacy }} />
      </div>
    </section>
  );
};

export default Payment;
