import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Modal,
  Pagination,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import ProductsComponent from '../../component/Products/ProductsComponent';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CategoryFilter from '../../component/Products/CategoryFilter';
import Title from '../../component/Helmet/Title';
import {
  useCategoriesWiseProductsQuery,
  useChildCategoryWiseProductsQuery,
  useGetCategoriesQuery,
  useGetSubCategoriesQuery,
} from '../../redux/Features/ServicesApi';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const ChildCategoryProducts = () => {
  const { slug, id } = useParams();
  const [page, setPage] = useState(1);
  const [selectedCategories, setSelectedCategories] = useState(null);
  const [products, setProducts] = useState([]);
  const [sortProduct, setSortProduct] = useState();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  let childCategorySlug = selectedCategories ? selectedCategories : slug;
  if (selectedCategories === 'none') {
    childCategorySlug = slug;
  }

  const { data, isLoading } = useChildCategoryWiseProductsQuery(
    { id: id, slug: childCategorySlug },
    {
      refetchOnMountOrArgChange: true,
      keepUnusedDataFor: 0,
    }
  );

  const { data: categories } = useGetCategoriesQuery();
  const { data: subCategories } = useGetSubCategoriesQuery();

  useEffect(() => {
    setProducts([]);
  }, [childCategorySlug, id]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (data) {
      setProducts(data?.data);
    }
  }, [data]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <div className="my-10">
      <Title
        title={`${slug} products`}
        content={`This is ${slug} products page of chader alo.`}
      />
      <div>
        <div className="hidden lg:block">
          <div className="flex items-center justify-between mx-auto bg-secondary-100 px-4">
            <Link to={'/'}>
              <Button variant="contained" style={{ backgroundColor: 'black' }}>
                Back
              </Button>
            </Link>
            <h3 className="text-center my-5 lg:my-10 uppercase">{slug}</h3>
            <div>
              <form className="max-w-sm mx-auto">
                <select
                  id="sort"
                  onChange={(e) => setSortProduct(e.target.value)}
                  className="bg-secondary-950 border border-secondary-300 text-white text-sm rounded-lg focus:ring-secondary-500 focus:border-secondary-500 block w-full p-2.5 uppercase"
                >
                  <option defaultValue>Sort By Availability</option>
                  <option value="default">Default</option>
                  <option value="hl">High to low</option>
                  <option value="lh">Low to high</option>
                </select>
              </form>
            </div>
          </div>
        </div>
        {/* for small and medium devices */}
        <div className="block lg:hidden inset-y-0">
          <div className="flex items-center justify-between">
            <Link to={'/'}>
              <Button variant="contained" style={{ backgroundColor: 'black' }}>
                Back
              </Button>
            </Link>
            {/* title */}
            <h3 className="text-center my-10 uppercase">{slug}</h3>
            {/* filter */}
            <div>
              <Button
                variant="contained"
                onClick={handleOpen}
                style={{ backgroundColor: 'black' }}
              >
                Filter
              </Button>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    <p>Filter products</p>
                  </Typography>
                  <Typography
                    variant="div"
                    id="modal-modal-description"
                    sx={{ mt: 2 }}
                  >
                    <div>
                      <Accordion defaultExpanded>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          <h6>Categories</h6>
                        </AccordionSummary>
                        <AccordionDetails>
                          <CategoryFilter
                            selectedCategories={selectedCategories}
                            setSelectedCategories={setSelectedCategories}
                            data={categories?.data}
                          />
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <h6>Sub Categories</h6>
                        </AccordionSummary>
                        <AccordionDetails>
                          <CategoryFilter
                            selectedCategories={selectedCategories}
                            setSelectedCategories={setSelectedCategories}
                            data={subCategories?.data}
                          />
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </Typography>
                </Box>
              </Modal>
            </div>
          </div>
        </div>
      </div>

      {/* Filter accordion */}
      <div className="lg:flex items-start justify-between mx-auto lg:my-10 gap-4">
        <div className="w-1/4 hidden lg:block">
          <div>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <h6>Categories</h6>
              </AccordionSummary>
              <AccordionDetails>
                <CategoryFilter
                  selectedCategories={selectedCategories}
                  setSelectedCategories={setSelectedCategories}
                  data={categories?.data}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <h6>Sub Categories</h6>
              </AccordionSummary>
              <AccordionDetails>
                <CategoryFilter
                  selectedCategories={selectedCategories}
                  setSelectedCategories={setSelectedCategories}
                  data={subCategories?.data}
                />
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
        {/* Products */}
        <div className="lg:w-3/4 hidden lg:block">
          <ProductsComponent
            products={products}
            isLoading={isLoading}
            grid={'grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'}
          />
        </div>
        <div className="w-full lg:hidden block">
          <ProductsComponent
            products={data?.data}
            isLoading={isLoading}
            grid={'grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'}
          />
        </div>
      </div>

      {/* Pagination */}
      <div className="flex items-center justify-center mx-auto">
        <Stack spacing={2}>
          <Pagination
            count={1}
            page={page}
            onChange={handleChange}
            showFirstButton
            showLastButton
            variant="outlined"
            shape="rounded"
          />
        </Stack>
      </div>
    </div>
  );
};

export default ChildCategoryProducts;
