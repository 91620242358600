import React from 'react';

const SizeGuide = () => {
  return (
    <div className="py-8 px-4 sm:px-8 lg:px-16">
      <h1 className="text-3xl font-bold mb-4 text-center">Size Guide.</h1>
      <p>
        "You will look gorgeous only when your dress fits you perfectly.
        Achieving the perfect fit is all about accurate measurement. To ensure
        your outfit compliments you best, match your body measurements with our
        size chart and then order accordingly. This way, you'll find the perfect
        fit that enhances your style and confidence." If you have any other
        details you'd like to add or modify, let me know!
      </p>
    </div>
  );
};

export default SizeGuide;
