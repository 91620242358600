import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Skeleton,
} from '@mui/material';
import React from 'react';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CompareIcon from '@mui/icons-material/Compare';

const ProductsSkeleton = () => {
  return (
    <div
      className={`grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 items-center gap-5`}
    >
      {[1, 2, 3, 4, 5, 6, 7, 8]?.map((i) => (
        <div key={i}>
          <Card sx={{ maxWidth: 1000 }} className="my-2 relative">
            <>
              <CardActionArea>
                <CardMedia
                  component="div"
                  className="bg-secondary-200 w-full h-96"
                />
                <CardContent>
                  <div>
                    <p className="text-lg text-secondary-500">
                      <Skeleton width={60} />
                    </p>
                    <p>
                      <Skeleton width={60} />
                    </p>
                    <div className="absolute z-30 bottom-5 rounded-md bg-white left-0 right-0 mx-4"></div>
                  </div>
                </CardContent>
              </CardActionArea>
            </>

            <div
              className={`flex items-center z-30 justify-center cursor-pointer gap-5 bg-secondary-50 w-44 lg:w-64 absolute right-0 left-0 mx-auto py-2 rounded-md`}
            >
              <div>
                <ShoppingCartIcon />
              </div>
              <div>
                <CompareIcon />
              </div>
            </div>
          </Card>
        </div>
      ))}
    </div>
  );
};

export default ProductsSkeleton;
