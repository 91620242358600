import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import Title from '../component/Helmet/Title';
import axios from 'axios';
import swal from 'sweetalert';
import {
  useDeleteCartMutation,
  useGetCartProductsQuery,
  usePlaceOrderMutation,
} from '../redux/Features/ServicesApi';
import CheckoutSkeleton from '../component/Skeleton/CheckoutSkeleton';

//* This is Checkout page
const Checkout = () => {
  const navigate = useNavigate();
  const [ip, setIp] = useState('');
  useEffect(() => {
    window.scrollTo(0, 0);
    //  Fetch IP address and set it to the state
    const fetchIpAddress = async () => {
      try {
        const response = await axios.get('https://api.ipify.org?format=json');
        setIp(response.data.ip);
      } catch (error) {
        // console.error("Error fetching IP address:", error);
        setIp('127.0.0.1');
      }
    };
    fetchIpAddress();
  }, []);
  const { data, isLoading } = useGetCartProductsQuery(ip);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      delivery: '100', // default value for the radio button
    },
  });

  const deliveryValue = watch('delivery'); // watch the delivery field

  const handleDeliveryChange = (event) => {
    setValue('delivery', event.target.value); // set the value of the delivery field
  };
  // place order mutation
  const [placeOrder] = usePlaceOrderMutation();

  // products details
  const productsDetails = data?.data?.carts
    ? data?.data?.carts?.map((c) => ({
        id: c?.product?.id,
        color: c?.color ? c?.color : null,
        price: c?.price,
        qty: c?.qty,
        size: c?.size ? c?.size : null,
      }))
    : [];

  // Total cards quantity
  const TotalQuantity = data?.data?.carts
    ? data?.data?.carts
        ?.reduce(
          (total, currentProduct) => total + Number(currentProduct.qty),
          0
        )
        .toString()
    : 0;
  const subTotal = Number(
    (data?.data?.subTotal + data?.data?.subTotal * 0.075 + 80).toFixed()
  );

  const onSubmit = async (data) => {
    const order = {
      ip_address: ip,
      customer_name: data.name,
      customer_phone: data.phone,
      delivery_area: data.delivery,
      customer_address: data.address,
      price: Number(subTotal),
      product_quantity: Number(TotalQuantity),
      payment_type: 'cod',
      order_type: 'Website',
      products: productsDetails,
    };

    if (productsDetails.length > 0) {
      try {
        const res = await placeOrder(order);

        if (res?.data?.success === true) {
          const orderId = res?.data?.data?.orderId;
          navigate(`/success-order/${orderId}`);
        }
      } catch (error) {
        // console.log(error);
      }
    } else {
      swal({
        title: 'Please add to cart products.',
        icon: 'error',
      });
    }
    reset();
  };

  const [deleteCart] = useDeleteCartMutation();

  // carts delete handler
  const deleteHandler = async (id) => {
    swal({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this product!',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          const res = await deleteCart(id).unwrap();

          swal('Good job!', res?.data?.message, 'success');
        } catch (error) {
          console.log(error);
        }
      } else {
        swal('Your product is safe!');
      }
    });
  };
  return (
    <>
      {isLoading ? (
        <CheckoutSkeleton />
      ) : (
        <div className="my-24 lg:my-10">
          <h3 className="text-center my-5 lg:my-10">Checkout</h3>
          <section className="">
            <Title
              title={'Checkout'}
              content={'This is checkout page of chader alo.'}
            />
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="lg:flex justify-between items-start gap-5 mx-auto"
              id="checkout_form"
            >
              <div className="w-full xl:w-2/3 rounded-md bg-white shadow-xl py-2 lg:py-10 px-2 lg:px-6 my-6 lg:my-0">
                <h3 className="text-xl lg:text-2xl font-semibold py-2">
                  Billing / Shipping Details
                </h3>
                <div className="lg:flex justify-between items-center gap-4 my-4">
                  <div className="w-full">
                    <input
                      type="text"
                      name="name"
                      placeholder="Enter full Name*"
                      className="bg-base lg:py-4 w-full border border-base_300 text-dark rounded-lg focus:ring-primary focus:border-primary block p-2.5"
                      {...register('name', { required: true })}
                      aria-invalid={errors.name ? 'true' : 'false'}
                    />
                    <p className="py-1">
                      {errors.name?.type === 'required' && (
                        <small role="alert" className="text-danger">
                          name is required
                        </small>
                      )}
                    </p>
                  </div>
                  <div className="w-full">
                    <input
                      type="tel"
                      name="phone"
                      placeholder="Phone*"
                      className="bg-base w-full lg:py-4 border border-base_300 text-dark rounded-lg focus:ring-primary focus:border-primary block p-2.5"
                      {...register('phone', { required: true })}
                    />
                    <p className="py-1">
                      {errors.phone?.type === 'required' && (
                        <small role="alert" className="text-danger">
                          Phone is required
                        </small>
                      )}
                    </p>
                  </div>
                </div>
                <div className="my-4">
                  <textarea
                    name="address"
                    cols="30"
                    rows="10"
                    placeholder="Enter your full Address*"
                    className="bg-base w-full lg:py-4 border border-base_300 text-dark rounded-lg focus:ring-primary focus:border-primary block p-2.5"
                    {...register('address', { required: true })}
                    aria-invalid={errors.address ? 'true' : 'false'}
                  ></textarea>
                  <p className="py-1">
                    {errors.address?.type === 'required' && (
                      <small role="alert" className="text-danger">
                        Address is required
                      </small>
                    )}
                  </p>
                </div>
                <div className="my-4">
                  <div className="mb-2">
                    <input
                      type="radio"
                      value="80"
                      name="delivery"
                      id="inside"
                      className="defaultChecked"
                      {...register('delivery', { required: true })}
                      defaultChecked
                      onChange={handleDeliveryChange}
                    />
                    <label htmlFor="inside">Inside Dhaka(80 TK.)</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      name="delivery"
                      value="150"
                      id="outside"
                      {...register('delivery', { required: true })}
                      onChange={handleDeliveryChange}
                    />
                    <label htmlFor="outside">Outside Dhaka(150 TK.)</label>
                  </div>
                </div>
              </div>
              <div className="w-full xl:w-1/3 bg-white rounded-md shadow-xl p-2 lg:p-6 h-fit">
                <div className="overflow-y-scroll h-[170px]">
                  {data?.data?.carts?.length > 0 ? (
                    <>
                      {data?.data?.carts.map((cProducts, index) => (
                        <div
                          className="flex justify-between items-center mx-auto gap-4 my-4 border rounded-md"
                          key={index}
                        >
                          <div>
                            <img
                              src={cProducts?.product?.imageUrl}
                              alt={cProducts?.product?.name}
                              className="w-20"
                            />
                          </div>
                          <div className="w-72">
                            <h4 className="block lg:hidden">
                              {cProducts?.product?.name}
                            </h4>
                            <h4 className="hidden lg:block">
                              {cProducts?.product?.name}
                            </h4>
                            <div>
                              <p className="text-primary font-medium">
                                {Number(
                                  cProducts?.product?.regular_price
                                ).toFixed() * cProducts?.qty}{' '}
                                TK.
                              </p>
                              <p>{cProducts?.qty} Item</p>
                              <div className="flex items-center gap-2 mx-auto">
                                <p>
                                  Size:{' '}
                                  {cProducts?.size
                                    ? cProducts?.size
                                    : 'No size'}
                                </p>
                                <p>
                                  Color:
                                  {cProducts?.color
                                    ? cProducts?.color
                                    : 'No color'}
                                </p>
                              </div>
                            </div>
                          </div>
                          <button
                            onClick={() => deleteHandler(cProducts?.id)}
                            className="text-primary active:text-danger"
                          >
                            <DeleteIcon />
                          </button>
                        </div>
                      ))}
                    </>
                  ) : (
                    "You haven't added any product!"
                  )}
                </div>

                <hr className="border my-2" />
                <div>
                  <div className="flex justify-between my-1 items-center">
                    <p className="font-semibold">Sub Total</p>
                    <p>{data?.data?.subTotal} Tk.</p>
                  </div>
                  {/* <div className="flex items-center justify-between py-2">
                    <p className="font-semibold">Tax</p>
                    <p>7.5%</p>
                  </div> */}
                  <div className="flex justify-between my-1 items-center">
                    <p className="font-semibold">Delivery Charge</p>
                    <p>{Number(deliveryValue).toFixed()} Tk.</p>
                  </div>
                  <hr className="border my-2" />
                  <div className="flex justify-between items-center">
                    <p className="font-semibold">Grand Total</p>
                    <p>
                      {/* {(
                        data?.data?.subTotal +
                        data?.data?.subTotal * 0.075 +
                        Number(deliveryValue)
                      ).toFixed()}{' '} */}
                      {(data?.data?.subTotal + Number(deliveryValue)).toFixed()}{' '}
                      Tk.
                    </p>
                  </div>
                  <div className="my-2">
                    <div>
                      <p className="my-1 font-semibold">
                        Select Payment Method
                      </p>

                      <input
                        type="radio"
                        name="paymentMethod"
                        id=""
                        value="Cash on Delivery"
                        defaultChecked
                      />
                      <label htmlFor="paymentMethod">Cash on delivery</label>
                    </div>
                    {/* <div className="my-2">
                      <p className="text-lg font-medium">Use Coupon Code</p>
                      <div className="flex items-center justify-between gap-5">
                        <div className="w-full">
                          <input
                            type="text"
                            name="coupon"
                            placeholder="Use Coupon Code"
                            className="bg-base w-full border border-base_300 text-dark rounded-lg focus:ring-primary focus:border-primary block p-2.5"
                            {...register("coupon")}
                          />
                        </div>
                        <div className="w-full " type="button">
                          <p className="text-center cursor-pointer bg-secondary-950 text-white p-2.5 rounded-md">
                            Apply
                          </p>
                        </div>
                      </div>
                    </div> */}
                    <div>
                      <button className="bg-secondary-950 py-2 w-full mt-6 text-white rounded-md">
                        Place an Order{' '}
                        <i className="fa-solid fa-right-from-bracket"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </section>
        </div>
      )}
    </>
  );
};

export default Checkout;
