import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Skeleton,
} from '@mui/material';
import React from 'react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

const TrendingCategoriesSkeleton = () => {
  return (
    <div className="my-5 lg:my-10 animate-pulse">
      <h3 className="text-center my-5 lg:my-10">
        <Skeleton width={300} className="mx-auto" />
      </h3>
      <div>
        <div className="mt-5">
          <Swiper
            slidesPerView={2}
            loop={true}
            spaceBetween={6}
            breakpoints={{
              640: {
                slidesPerView: 2,
                spaceBetween: 6,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 8,
              },
              1024: {
                slidesPerView: 5,
                spaceBetween: 10,
              },
            }}
            pagination={{
              clickable: true,
            }}
            modules={[Navigation, Autoplay, Pagination]}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            className=" mt-4 space-x-2 mySwiper cursor-pointer"
          >
            {[1, 2, 3, 4, 5].map((c) => (
              <SwiperSlide key={c}>
                <Card sx={{ maxWidth: 1000 }} className="my-2 relative">
                  <CardActionArea>
                    <CardMedia
                      component="div"
                      className="bg-secondary-200 w-full h-60 md:h-96"
                    />
                    <CardContent className="absolute z-30 bottom-5 rounded-md bg-white left-0 right-0 mx-4">
                      <h5 className="text-center">
                        <Skeleton width={100} className="mx-auto" />
                      </h5>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default TrendingCategoriesSkeleton;
