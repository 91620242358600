import React from 'react';

const DigitalGiftVoucherPage = () => {
  return (
    <div className="bg-gray-100 min-h-screen p-8">
      <div className="container mx-auto bg-white p-8 rounded-lg shadow-md">
        <h1 className="text-3xl font-bold mb-6 text-center">
          Salvia Digital Gift Voucher Terms & Conditions
        </h1>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">
            How to Purchase and Redeem
          </h2>
          <p>
            Customers can purchase "Salvia Digital Gift Vouchers" on
            salvia.clothing by making an advance payment through their preferred
            payment method. These vouchers can be redeemed at any Salvia outlet
            nationwide to purchase Salvia products.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Terms and Conditions</h2>
          <ul className="list-disc ml-6">
            <li>Vouchers are valid for a limited time.</li>
            <li>They are non-refundable and cannot be exchanged for cash.</li>
            <li>
              If the purchase exceeds the voucher value, the balance must be
              paid separately.
            </li>
            <li>
              Vouchers are non-transferable, and Salvia is not responsible for
              lost or stolen vouchers.
            </li>
            <li>Multiple vouchers can be used in a single transaction.</li>
            <li>
              For more details, visit our website or contact customer service.
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Activation and Usage</h2>
          <p>
            Salvia will send the gift voucher's activation code to the
            redeemer's mobile number. Additionally, a confirmation email will be
            sent to the purchaser within 48 hours after full payment. The
            digital gift voucher(s) will be valid for 60 days from the date of
            activation.
          </p>
          <p>
            Customers can purchase multiple Salvia products using a single
            digital gift voucher in one invoice. However, multiple gift vouchers
            cannot be combined in a single transaction; each voucher must be
            redeemed separately with its own invoice.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Additional Terms</h2>
          <ul className="list-disc ml-6">
            <li>
              Vouchers can only be redeemed at Salvia physical stores across
              Bangladesh.
            </li>
            <li>
              Vouchers are not exchangeable, refundable, replaceable, or
              transferable for cash or credit.
            </li>
            <li>
              If purchased with a special offer, vouchers can only be used for
              products at regular prices.
            </li>
            <li>
              Customers must provide contact details for redeeming the voucher.
            </li>
            <li>
              If the product price exceeds the voucher value, the customer must
              pay the difference.
            </li>
            <li>
              Each voucher must be fully redeemed in a single transaction.
            </li>
            <li>Remaining balances on vouchers cannot be refunded.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Know Us</h2>
          <p>
            Salvia is a distinguished lifestyle brand from Bangladesh, known for
            its unique style and diverse range of collections. As a fashion
            brand, Salvia focuses on crafting fashionable attire and accessories
            for people of all ages who want to express their unique fashion
            sense and make a bold style statement.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Contact Information</h2>
          <p>
            House:13, (Flat: A-1) Sonargaon Janapath, Uttara,Sector 12 ,
            Dhaka:1230, Bangladesh.
          </p>
          <p>+8801898801900</p>
          <p>salvia.lifestylebd@gmail.com</p>
        </section>
      </div>
    </div>
  );
};

export default DigitalGiftVoucherPage;
