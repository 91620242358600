import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Autoplay, Pagination } from 'swiper/modules';
import { Card, CardActionArea, CardContent, CardMedia } from '@mui/material';
import { Link } from 'react-router-dom';
import './Trending.css';
import { useGetCategoriesQuery } from '../../../redux/Features/ServicesApi';
import TrendingCategoriesSkeleton from '../../Skeleton/Products/TrendingCategoriesSkeleton';

const TrendingCategories = () => {
  const { data, isLoading } = useGetCategoriesQuery();

  return (
    <>
      {isLoading ? (
        <TrendingCategoriesSkeleton />
      ) : (
        <div className="my-5 lg:my-10">
          <h3 className="text-center uppercase my-5 lg:my-10">
            Trending Categories
          </h3>
          <div>
            <div className="mt-5">
              <Swiper
                slidesPerView={2}
                loop={true}
                spaceBetween={6}
                breakpoints={{
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 6,
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 8,
                  },
                  1024: {
                    slidesPerView: 5,
                    spaceBetween: 10,
                  },
                }}
                pagination={{
                  clickable: true,
                }}
                modules={[Navigation, Autoplay, Pagination]}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                className=" mt-4 space-x-2 mySwiper cursor-pointer"
              >
                {data?.data.map((c, index) => (
                  <SwiperSlide key={index}>
                    <Card sx={{ maxWidth: 1000 }} className="my-2 relative">
                      <Link to={`/products-category/${c?.slug}`}>
                        <CardActionArea>
                          <CardMedia
                            component="img"
                            image={c?.imageUrl}
                            alt={c?.name}
                            className="h-60 md:h-96"
                          />
                          <CardContent className="absolute z-30 bottom-5 rounded-md bg-white left-0 right-0 mx-4 border shadow-lg">
                            <h5 className="text-center">{c?.name}</h5>
                          </CardContent>
                        </CardActionArea>
                      </Link>
                    </Card>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TrendingCategories;
