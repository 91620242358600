import React from 'react';
import { Clock, Phone, MessageSquare } from 'lucide-react';

const CustomerService = () => {
  return (
    <div className="bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className=" mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="px-6 py-8">
          <h1 className="text-3xl font-bold text-center text-gray-800 mb-8">
            Customer Service
          </h1>

          <p className="text-gray-600 mb-6">
            We believe in customer satisfaction and customer priority. When you
            place any requirement, our team is committed to serving you within a
            specific period.
          </p>

          <h2 className="text-2xl font-semibold text-gray-800 mb-4">
            Service hours:
          </h2>
          <p className="text-gray-600 mb-6">
            We value your time. Our service team is ready to give you the
            desired service.
          </p>

          <ul className="space-y-4 mb-8">
            <li className="flex items-start">
              <Clock className="mr-3 mt-1 text-blue-500" size={20} />
              <span>You can place your order 24/7</span>
            </li>
            <li className="flex items-start">
              <Clock className="mr-3 mt-1 text-blue-500" size={20} />
              <span>
                Our team will contact you within 12 hours for order
                confirmation.
              </span>
            </li>
            <li className="flex items-start">
              <Phone className="mr-3 mt-1 text-blue-500" size={20} />
              <span>
                Our customer service numbers are in service for you from 10 am–
                9 am ( except sunday and other holidays)
              </span>
            </li>
            <li className="flex items-start">
              <MessageSquare className="mr-3 mt-1 text-blue-500" size={20} />
              <span>
                Our online chat option is ready to serve you 10 am- 9 pm ( Each
                day)
              </span>
            </li>
          </ul>

          <p className="text-xl font-semibold text-center text-gray-800">
            Happy Shopping!!
          </p>
        </div>
      </div>
    </div>
  );
};

export default CustomerService;
