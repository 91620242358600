import React from 'react';

const CategoryFilter = ({
  selectedCategories,
  setSelectedCategories,
  data,
}) => {
  return (
    <div>
      <div className="space-y-2">
        {data?.map((category, index) => (
          <div key={index} className="flex items-center">
            <input
              id={`category-${category.id}`}
              type="radio"
              name="category"
              value={category.slug}
              className="w-4 h-4 text-secondary-600 bg-gray-100 border-gray-300 rounded focus:ring-secondary-500"
              onChange={() => setSelectedCategories(category.slug)}
            />
            <label
              htmlFor={`category-${category.id}`}
              className="ml-2 text-sm font-medium text-gray-900"
            >
              {category.name}
            </label>
          </div>
        ))}
        <div className="flex items-center">
          <input
            id={`category`}
            type="radio"
            name="category"
            value={'none'}
            className="w-4 h-4 text-secondary-600 bg-gray-100 border-gray-300 rounded focus:ring-secondary-500"
            onChange={() => setSelectedCategories('none')}
          />
          <label
            htmlFor={`category`}
            className="ml-2 text-sm font-medium text-gray-900"
          >
            none
          </label>
        </div>
      </div>
    </div>
  );
};

export default CategoryFilter;
