import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

// search field
const MobileSearch = ({ setOpen }) => {
  const navigate = useNavigate();

  const { handleSubmit, reset, register } = useForm();

  const onSubmit = (data) => {
    navigate(`/products-search/${data.search}`);

    reset();
    setOpen(false);
  };

  return (
    <div className="bg-white w-full p-4">
      <div className="flex items-center justify-end">
        <div onClick={() => setOpen(false)}>
          <CloseIcon />
        </div>
      </div>
      <form className="max-w-md mx-auto my-4" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <label
            htmlFor="default-search"
            className="mb-2 text-sm font-medium text-gray-900 sr-only "
          >
            Search
          </label>
          <input
            type="search"
            id="default-search"
            {...register('search', { required: true })}
            className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-secondary-500 focus:border-secondary-500 "
            placeholder="Search here..."
            required
          />
        </div>
        <div className="my-2">
          <button
            type="submit"
            className="text-white w-full bg-secondary-900 hover:bg-secondary-800 focus:ring-4 focus:outline-none focus:ring-secondary-300 font-medium rounded-lg text-sm px-4 py-2 "
          >
            Search
          </button>
        </div>
      </form>
    </div>
  );
};

export default MobileSearch;
