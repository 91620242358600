import React, { useEffect, useState } from 'react';
import CartComponent from '../component/Cart/CartComponent';
import Title from '../component/Helmet/Title';
import axios from 'axios';
import { useGetCartProductsQuery } from '../redux/Features/ServicesApi';
import CartSkeleton from '../component/Skeleton/CartSkeleton';

//* This is ViewCart page
const ViewCart = () => {
  const [ip, setIp] = useState();
  useEffect(() => {
    window.scrollTo(0, 0);
    //  Fetch IP address and set it to the state
    const fetchIpAddress = async () => {
      try {
        const response = await axios.get('https://api.ipify.org?format=json');
        setIp(response.data.ip);
      } catch (error) {
        // console.error("Error fetching IP address:", error);
        setIp('127.0.0.1');
      }
    };
    fetchIpAddress();
  }, []);
  const { data, isLoading } = useGetCartProductsQuery(ip);

  return (
    <>
      {isLoading ? (
        <CartSkeleton />
      ) : data?.data?.carts?.length > 0 ? (
        <div className="my-24 lg:my-10">
          {/* This is Viewcart page helmet component */}
          <Title
            title={'View cart'}
            content={'This is view cart page of chader alo.'}
          />

          <h3 className="my-5 lg:my-10 text-center">Your Cart</h3>
          {/* ViewCart component */}
          <CartComponent products={data?.data?.carts} />
        </div>
      ) : (
        <div className="my-24 lg:my-10 text-center">
          <p>You haven't added any product!</p>
        </div>
      )}
    </>
  );
};

export default ViewCart;
