import React, { useEffect } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { Skeleton } from '@mui/material';

//* This is CheckoutSkeleton page
const CheckoutSkeleton = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="my-24 lg:my-10 animate-pulse">
      <Skeleton width={200} className="mx-auto" />
      <section className="">
        <form
          className="lg:flex justify-between items-start gap-5 mx-auto"
          id="checkoutSkeleton_form"
        >
          <div className="w-full xl:w-2/3 rounded-md bg-white shadow-xl py-2 lg:py-10 px-2 lg:px-6 my-6 lg:my-0">
            <Skeleton width={200} className="mx-auto" />
            <div className="lg:flex justify-between items-center gap-4 my-4">
              <div className="w-full">
                <Skeleton width={450} height={100} className="mx-auto" />
              </div>
              <div className="w-full">
                <Skeleton width={450} height={100} className="mx-auto" />
              </div>
            </div>
            <div className="my-4">
              <div className="w-full h-96 bg-secondary-200 rounded-md"></div>
            </div>
            <div className="my-4">
              <div className="mb-2">
                <Skeleton width={200} />
              </div>
              <div>
                <Skeleton width={200} />
              </div>
            </div>
          </div>
          <div className="w-full xl:w-1/3 bg-white rounded-md shadow-xl p-2 lg:p-6 h-fit">
            <div className="overflow-y-scroll h-[200px]">
              {2 > 0 ? (
                <>
                  {[1, 2].map((cProducts) => (
                    <div
                      className="flex justify-between items-center mx-auto gap-4 my-4 border rounded-md"
                      key={cProducts}
                    >
                      <div className="w-20 h-20 bg-secondary-200 rounded-md mx-auto"></div>
                      <div className="w-72">
                        <Skeleton width={100} />
                        <Skeleton width={100} />
                        <div>
                          <p className="text-primary font-medium">
                            {' '}
                            <Skeleton width={70} />
                          </p>
                          <Skeleton width={70} />
                          <div className="flex items-center gap-2 mx-auto">
                            <Skeleton width={100} />
                          </div>
                        </div>
                      </div>
                      <button className="text-primary active:text-danger">
                        <DeleteIcon />
                      </button>
                    </div>
                  ))}
                </>
              ) : (
                "You haven't added any product!"
              )}
            </div>

            <hr className="border my-2" />
            <div>
              <div className="flex justify-between my-1 items-center">
                <Skeleton width={100} />
                <Skeleton width={100} />
              </div>
              <div className="flex items-center justify-between py-2">
                <Skeleton width={100} />
                <Skeleton width={100} />
              </div>
              <div className="flex justify-between my-1 items-center">
                <Skeleton width={100} />
                <Skeleton width={100} />
              </div>
              <div className="flex justify-between my-1 items-center">
                <Skeleton width={100} />
                <Skeleton width={100} />
              </div>
              <hr className="border my-2" />
              <div className="flex justify-between items-center">
                <Skeleton width={100} />
                <Skeleton width={100} />
              </div>
              <div className="my-2">
                <div>
                  <Skeleton width={100} />

                  <Skeleton width={200} />
                </div>
                <div className="my-2">
                  <Skeleton width={100} />
                  <div className="flex items-center justify-between gap-5">
                    <div className="w-full bg-secondary-200 h-10 rounded-md"></div>
                    <div className="w-full " type="button">
                      <Skeleton width={200} height={70} />
                    </div>
                  </div>
                </div>
                <div>
                  <Skeleton width={300} height={70} className="mx-auto" />
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
};

export default CheckoutSkeleton;
