import React from 'react';
import { Phone, MessageCircle, Mail, MapPin, Clock } from 'lucide-react';

const StoreLocator = () => {
  return (
    <div className="bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className=" mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="px-6 py-8">
          <h1 className="text-3xl font-bold text-center text-gray-800 mb-8">
            Store Locator & Service Points
          </h1>

          <div className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              Service Points:
            </h2>
            <ul className="space-y-4">
              <li className="flex items-start">
                <Phone className="mr-3 mt-1 text-blue-500" size={20} />
                <span>
                  For order related query Call us @ +8801898801900 (From 9am –
                  7pm, except sunday and other holidays)
                </span>
              </li>
              <li className="flex items-start">
                <Phone className="mr-3 mt-1 text-blue-500" size={20} />
                <span>
                  For General query Call us @ +8801898801900 (From 9am – 10 pm,
                  each day)
                </span>
              </li>
              <li className="flex items-start">
                <MessageCircle className="mr-3 mt-1 text-blue-500" size={20} />
                <span>
                  Instant chat with us @ www.facebook.com/salvialifestylebd
                </span>
              </li>
              <li className="flex items-start">
                <Mail className="mr-3 mt-1 text-blue-500" size={20} />
                <span>
                  E-mail us for any more information:
                  salvia.lifestylebd@gmail.com
                </span>
              </li>
            </ul>
          </div>

          <div>
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              Store locator:
            </h2>
            <ul className="space-y-4">
              <li className="flex items-center">
                <Phone className="mr-3 text-blue-500" size={20} />
                <span>01898801900</span>
              </li>
              <li className="flex items-start">
                <MapPin className="mr-3 mt-1 text-blue-500" size={20} />
                <span>
                  House:13, (Flat: A-1) Sonargaon Janapath, Uttara,Sector 12 ,
                  Dhaka:1230, Bangladesh.
                </span>
              </li>
              <li className="flex items-center">
                <Clock className="mr-3 text-blue-500" size={20} />
                <span>10AM - 10PM</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoreLocator;
