import React from 'react';
import { Helmet } from 'react-helmet';

// react helmet component
const Title = ({ title, content }) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title} - Chader alo</title>
      <meta name="description" content={content} />
    </Helmet>
  );
};

export default Title;
