import React from 'react';
import { useBottomBannerQuery } from '../../redux/Features/ServicesApi';
import PromotionalBannerSkeleton from '../Skeleton/PromotionalBannerSkeleton';

const PromotionalBanner = () => {
  const { data: banner, isLoading } = useBottomBannerQuery();

  return (
    <>
      {isLoading ? (
        <PromotionalBannerSkeleton />
      ) : (
        <div className="my-5 lg:my-10">
          <div className="lg:flex items-center justify-between gap-4 mx-auto space-y-2 lg:space-y-0">
            {banner?.data?.map((b, index) => (
              <div className="mx-auto" key={index}>
                <img src={b?.imageUrl} alt="bottom-banner" />
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default PromotionalBanner;
