import React from 'react';

const Privacy = () => {
  return (
    <div className="py-8 px-4 sm:px-8 lg:px-16">
      <h1 className="text-3xl font-bold mb-4 text-center">Privacy Policy</h1>

      <div className="space-y-6">
        <p>
          At salvia.clothing, accessible from www.salvia.clothing, safeguarding
          the privacy of our visitors is one of our top priorities. This Privacy
          Policy outlines the types of information collected and recorded by
          salvia.clothing and explains how we use it.
        </p>
        <p>
          If you have any questions or need further clarification regarding our
          Privacy Policy, please feel free to contact us.
        </p>
        <p>
          Please note that this Privacy Policy applies solely to our online
          activities and is relevant for visitors to our website in relation to
          the information they have shared and/or collected on salvia.clothing.
          This policy does not apply to any information gathered offline or
          through channels other than this website.
        </p>

        <h2 className="text-2xl font-semibold">Consent:</h2>
        <p>
          By using our website, you hereby consent to our Privacy Policy and
          agree to its terms.
        </p>

        <h2 className="text-2xl font-semibold">How we use your information:</h2>
        <p>We use the information we collect in various ways, including to:</p>
        <ul className="list-disc list-inside">
          <li>Provide, operate, and maintain our website</li>
          <li>Improve, personalize, and expand our website</li>
          <li>Understand and analyze how you use our website</li>
          <li>Develop new products, services, features, and functionality</li>
          <li>
            Communicate with you for customer service, updates, and promotional
            purposes
          </li>
          <li>Send you emails</li>
          <li>Detect and prevent fraud</li>
        </ul>

        <h2 className="text-2xl font-semibold">Log Files:</h2>
        <p>
          salvia.clothing follows a standard procedure of using log files. These
          files log visitors when they visit websites, a common practice among
          all hosting companies as part of their analytics services. The
          information collected by log files includes internet protocol (IP)
          addresses, browser type, Internet Service Provider (ISP), date and
          time stamps, referring/exit pages, and possibly the number of clicks.
          This information is not linked to anything personally identifiable.
          The purpose of collecting this information is to analyze trends,
          administer the site, track users' movement on the website, and gather
          demographic information.
        </p>

        <h2 className="text-2xl font-semibold">Cookies and Web Beacons:</h2>
        <p>
          Like any other website, salvia.clothing uses "cookies." These cookies
          are used to store information, including visitors' preferences and the
          pages on the website that the visitor accessed or visited. This
          information is used to optimize the users' experience by customizing
          our web page content based on visitors' browser types and/or other
          information
        </p>

        <h2 className="text-2xl font-semibold">
          Advertising Partners Privacy Policies:
        </h2>
        <p>
          You may consult this list to find the Privacy Policy for each of the
          advertising partners of salvia.clothing. Third-party ad servers or ad
          networks use technologies like cookies, JavaScript, or web beacons in
          their respective advertisements and links that appear on
          salvia.clothing, which are sent directly to users' browsers. When this
          happens, they automatically receive your IP address. These
          technologies are used to measure the effectiveness of their
          advertising campaigns and/or to personalize the advertising content
          that you see on websites you visit. Please note that salvia.clothing
          has no access to or control over these cookies used by third-party
          advertisers.
        </p>

        <h2 className="text-2xl font-semibold">
          Third-Party Privacy Policies:
        </h2>
        <p>
          salvia.clothing's Privacy Policy does not apply to other advertisers
          or websites. Therefore, we advise you to consult the respective
          Privacy Policies of these third-party ad servers for more detailed
          information. These policies may include their practices and
          instructions on how to opt out of certain options. You can find a
          complete list of these Privacy Policies and their links here: Privacy
          Policy Links. You can choose to disable cookies through your
          individual browser options. For more detailed information about
          managing cookies with specific web browsers, please refer to the
          browsers' respective websites. [What Are Cookies?]
        </p>

        <h2 className="text-3xl font-semibold">
          CCPA Privacy Rights (Do Not Sell My Personal Information):
        </h2>
        <h3 className="text-xl font-semibold">
          <b>
            Under the CCPA, among other rights, California consumers have the
            right to:
          </b>
        </h3>
        <ul className="list-decimal pl-6">
          <li>
            Request that a business discloses the categories and specific pieces
            of personal data it has collected about consumers.
          </li>
          <li>
            Request that a business deletes any personal data it has collected
            about the consumer.
          </li>
          <li>
            Request that a business that sells personal data refrains from
            selling the consumer's personal data.
          </li>
        </ul>
        <p>
          If you make a request, we have one month to respond. If you would like
          to exercise any of these rights, please contact us.
        </p>

        <h2 className="text-2xl font-semibold">GDPR Data Protection Rights:</h2>
        <p>
          We want to ensure you, Tahsina Khatun, are aware of all your data
          protection rights. As a user, you are entitled to the following:
        </p>
        <ul className="list-decimal pl-6">
          <li>
            The right to access – You can request copies of your personal data.
            We may charge a small fee for this service.
          </li>
          <li>
            The right to rectification – You can request corrections to any
            information you believe is inaccurate or request that we complete
            information you believe is incomplete.
          </li>
          <li>
            The right to erasure – You can request the deletion of your personal
            data under certain conditions.
          </li>
          <li>
            The right to restrict processing – You can request that we limit the
            processing of your personal data under certain conditions.
          </li>
          <h2 className="text-xl my-2 font-semibold">
            You also have the following rights:
          </h2>
          <li>
            The right to object to processing – You can object to our processing
            of your personal data under certain conditions.
          </li>
          <li>
            The right to data portability – You can request that we transfer the
            data we have collected to another organization or directly to you,
            under certain conditions.
          </li>
        </ul>

        <p>
          If you make a request, we have one month to respond. If you would like
          to exercise any of these rights, please contact us.
        </p>

        <h2 className="text-2xl font-semibold">Children's Information:</h2>
        <p>
          Another key priority for us is ensuring the protection of children
          while they use the internet. We encourage parents and guardians to
          observe, participate in, monitor, and guide their children’s online
          activities. <br /> <br /> Salvia.clothing does not knowingly collect
          any Personally Identifiable Information (PII) from children under the
          age of 13. If you believe that your child has provided such
          information on our website, please contact us immediately. We will
          make every effort to promptly remove that information from our
          records. <br /> <br /> Your cooperation helps us maintain a safe and
          secure online environment for everyone.
        </p>
        <div>
          <p>
            salvia.clothing, যা www.salvia.clothing থেকে অ্যাক্সেসযোগ্য, আমাদের
            প্রধান অগ্রাধিকারগুলির মধ্যে একটি হল আমাদের দর্শকদের গোপনীয়তা। এই
            গোপনীয়তা নীতিতে সেই ধরনের তথ্য অন্তর্ভুক্ত রয়েছে যা
            salvia.clothing দ্বারা সংগ্রহ এবং রেকর্ড করা হয় এবং আমরা কীভাবে সেই
            তথ্য ব্যবহার করি। যদি আপনার আরও প্রশ্ন থাকে বা আমাদের গোপনীয়তা নীতি
            সম্পর্কে অতিরিক্ত তথ্যের প্রয়োজন হয়, তাহলে আমাদের সাথে যোগাযোগ
            করতে দ্বিধা করবেন না।
          </p>
          <h2 className="text-2xl font-semibold my-4">
            এই গোপনীয়তা নীতির প্রযোজ্যতা:
          </h2>
          <p>
            এই গোপনীয়তা নীতি শুধুমাত্র আমাদের অনলাইন কার্যক্রমের ক্ষেত্রে
            প্রযোজ্য এবং শুধুমাত্র সেই তথ্যের জন্য বৈধ যা আমাদের ওয়েবসাইটের
            দর্শকরা salvia.clothing-এ শেয়ার করেছেন এবং/অথবা আমরা সংগ্রহ করেছি।
            এটি অফলাইনে বা অন্য কোনো চ্যানেলের মাধ্যমে সংগৃহীত তথ্যের জন্য
            প্রযোজ্য নয়।
          </p>
          <h2 className="text-2xl font-semibold my-4">সম্মতি:</h2>
          <p>
            আমাদের ওয়েবসাইট ব্যবহার করে, আপনি আমাদের গোপনীয়তা নীতিতে সম্মত হন
            এবং এর শর্তাবলীতে আপনার সম্মতি প্রদান করেন।
          </p>
          <h2 className="text-2xl font-semibold my-4">
            আমরা যে তথ্য সংগ্রহ করি:
          </h2>
          <p>
            আমাদের পণ্য ও সেবা আরও উন্নত করতে এবং গ্রাহকের চাহিদা অনুযায়ী
            কাস্টমাইজ করতে আমরা বিভিন্ন ধরনের তথ্য সংগ্রহ করি। আপনার যদি আরও
            কোনো নির্দিষ্ট তথ্য যোগ করার প্রয়োজন হয় বা সম্পাদনা করতে চান, দয়া
            করে জানান! salvia.clothing, যা{' '}
            <a href="www.salvia.clothing" className="text-blue-500 underline">
              www.salvia.clothing
            </a>{' '}
            থেকে অ্যাক্সেসযোগ্য, আমাদের দর্শকদের গোপনীয়তা আমাদের প্রধান
            অগ্রাধিকারের মধ্যে একটি। এই গোপনীয়তা নীতিতে অন্তর্ভুক্ত রয়েছে সেই
            তথ্য যা salvia.clothing দ্বারা সংগ্রহ এবং রেকর্ড করা হয় এবং আমরা
            কীভাবে সেই তথ্য ব্যবহার করি।
          </p>
          <h2 className="text-2xl font-semibold my-4">
            আপনি যে ব্যক্তিগত তথ্য প্রদান করতে বলা হয়েছে এবং কেন:
          </h2>
          <p>
            আমরা যখন আপনাকে আপনার ব্যক্তিগত তথ্য প্রদান করতে বলব, তখন আমরা
            আপনাকে স্পষ্টভাবে জানিয়ে দেব কেন এটি প্রয়োজন।
            <br />
            <br /> যদি আপনি সরাসরি আমাদের সাথে যোগাযোগ করেন, আমরা আপনার নাম,
            ইমেল ঠিকানা, ফোন নম্বর, বার্তার বিষয়বস্তু, এবং যেকোনো সংযুক্তি পেতে
            পারি, যা আপনি প্রদান করতে বেছে নিতে পারেন। আপনি যখন একটি
            অ্যাকাউন্টের জন্য নিবন্ধন করবেন, তখন আমরা আপনার নাম, কোম্পানির নাম,
            ঠিকানা, ইমেল ঠিকানা এবং টেলিফোন নম্বরের মতো যোগাযোগের তথ্য চাইতে
            পারি।
          </p>
          <h2 className="text-2xl font-semibold my-4">
            আমরা কিভাবে আপনার তথ্য ব্যবহার করি:
          </h2>
          <p>আমরা বিভিন্ন উপায়ে আপনার তথ্য ব্যবহার করি, যার মধ্যে রয়েছে:</p>
          <ul className="list-disc pl-6">
            <li>আমাদের ওয়েবসাইট পরিচালনা এবং বজায় রাখা</li>
            <li>ওয়েবসাইট উন্নত করা, ব্যক্তিগতকৃত করা এবং প্রসারিত করা</li>
            <li>ওয়েবসাইট ব্যবহার বিশ্লেষণ করা</li>
            <li>নতুন পণ্য, পরিষেবা, বৈশিষ্ট্য এবং কার্যকারিতা উন্নয়ন করা</li>
            <li>
              আপনাকে গ্রাহক পরিষেবা, আপডেট, এবং প্রচারণার জন্য যোগাযোগ করা
            </li>
            <li>ইমেল পাঠানো</li>
            <li>জালিয়াতি শনাক্ত এবং প্রতিরোধ করা</li>
          </ul>
          <h2 className="text-2xl font-semibold my-4">লগ ফাইল:</h2>
          <p>
            salvia.clothing লগ ফাইল ব্যবহারের একটি আদর্শ পদ্ধতি অনুসরণ করে। যখন
            দর্শকরা আমাদের ওয়েবসাইট পরিদর্শন করেন, তখন এই ফাইলগুলি লগ করা হয়
            এবং এতে IP ঠিকানা, ব্রাউজারের ধরন, ISP, তারিখ এবং সময় স্ট্যাম্প,
            উল্লেখ/প্রস্থান পৃষ্ঠা, এবং ক্লিকের সংখ্যা অন্তর্ভুক্ত থাকতে পারে।
            এই তথ্যটি প্রবণতা বিশ্লেষণ, সাইট পরিচালনা, ব্যবহারকারীর গতিবিধি
            ট্র্যাকিং, এবং জনসংখ্যা সংক্রান্ত তথ্য সংগ্রহের জন্য ব্যবহৃত হয়।
          </p>
          <h2 className="text-2xl font-semibold my-4">কুকিজ এবং ওয়েব বীকন:</h2>
          <p>
            salvia.clothing 'কুকিজ' ব্যবহার করে যা দর্শকদের পছন্দ এবং
            ওয়েবসাইটের পৃষ্ঠাগুলি অ্যাক্সেস করা সম্পর্কে তথ্য সংরক্ষণ করতে
            ব্যবহৃত হয়। এই তথ্যটি ব্যবহারকারীর অভিজ্ঞতা অপ্টিমাইজ করতে ব্যবহার
            করা হয়।
          </p>
          <h2 className="text-2xl font-semibold my-4">
            তৃতীয় পক্ষের গোপনীয়তা নীতি:
          </h2>
          <p>
            salvia.clothing এর গোপনীয়তা নীতি তৃতীয় পক্ষের বিজ্ঞাপনদাতা বা
            অন্যান্য ওয়েবসাইটগুলিতে প্রযোজ্য নয়। আমরা আপনাকে তৃতীয় পক্ষের
            গোপনীয়তা নীতিগুলি পর্যালোচনা করার পরামর্শ দিই।
          </p>
          <h2 className="text-2xl font-semibold my-4">CCPA এবং GDPR অধিকার:</h2>
          <p>
            আমাদের ব্যবহারকারীদের ব্যক্তিগত ডেটার সুরক্ষা অধিকারগুলির প্রতি
            শ্রদ্ধা জানিয়ে আমরা ক্যালিফোর্নিয়ার CCPA এবং ইউরোপীয় GDPR
            নিয়মাবলী মেনে চলি। আপনার ডেটা সুরক্ষা অধিকারগুলি সম্পর্কে আরও জানতে
            আমাদের সাথে যোগাযোগ করুন। আপনার তথ্যের গোপনীয়তা এবং সুরক্ষা আমাদের
            কাছে অত্যন্ত গুরুত্বপূর্ণ, এবং আমরা এটি বজায় রাখতে প্রতিশ্রুতিবদ্ধ।
          </p>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
