import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Title from '../component/Helmet/Title';

const SuccessOrder = () => {
  const { id } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-b from-pink-100 to-white relative overflow-hidden">
      <Title
        title={'Thank you'}
        content={'This is success order page of chader alo.'}
      />
      <h1 className="text-4xl md:text-6xl font-bold text-secondary-950 mb-4">
        Thank You for Your Order!
      </h1>
      <p className="text-xl text-gray-700 mb-8">Your order no is {id}</p>
      <Link
        to={'/'}
        className="px-6 py-3 bg-secondary-950 text-white rounded-md hover:bg-secondary-900 transition duration-300"
      >
        Continue Shopping
      </Link>

      {/* Flower Animation */}
      <div className="absolute inset-0 pointer-events-none overflow-hidden">
        {[...Array(50)].map((_, i) => (
          <img
            key={i}
            src="/rose.png"
            alt="flower"
            className="absolute w-10 h-10 animate-fall"
            style={{
              animationDuration: `${Math.random() * 5 + 3}s`,
              animationDelay: `${Math.random() * 5}s`,
              left: `${Math.random() * 100}%`,
              top: `-${Math.random() * 20}vh`,
              transform: `rotate(${Math.random() * 360}deg)`,
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default SuccessOrder;
